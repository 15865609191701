import React, { Component, useState, useEffect, useContext, useRef, useCallback } from 'react';
import {
    View,
    SafeAreaView,
    Text,
    Alert,
    ScrollView,
    Image,
    Dimensions,
    TextInput,
    ActivityIndicator,
    useWindowDimensions,
} from 'react-native';
import { Button, Input, Card } from 'react-native-elements';
import styles from './styles';
import { Cell, Section, TableView } from 'react-native-tableview-simple';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { useFocusEffect } from '@react-navigation/native';
import { BUTTON_COLOR_ONE } from '../../../constants';


export default function Home() {

    const [users, setUsers] = useState([])
    const [pageLoading, setPageLoading] = useState(true)

    useEffect(() => {
        const userSubscriber = firebase.firestore().collection('userProfiles').onSnapshot(querySnapshot => {
            setPageLoading(true)
            const usersLocal = querySnapshot.docs.map(doc => doc)
            setUsers(usersLocal)
            setPageLoading(false)
        })
        return function cleanup() {
            userSubscriber();
        }
    }, [])

    const displayUsers = () => {
        const valueArray = []
        var index = 0
        users.forEach((user) => {
            valueArray.push(
                <View key={index}>
                    <Text>
                        {user.data().firstName} {user.data().lastName}, {user.data().contactEmail}, {user.data().contactNumber}, {user.data().userAddress}, Plan: {user.data().plan}, {user.data().stripeCustomerId}, {user.id}, Detergent: {user.data().detergent}, Dryer Sheet: {user.data().dryerSheet}, Fabric Softener: {user.data().fabricSoftener}, Additional Instructions: {user.data().additionalInstructions}
                    </Text>
                    <hr />
                </View>
            )
            index += 1
        })
        return (
            <View>
                {valueArray}
            </View>
        )
    }

    if (pageLoading) {
        return (
            <View>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView>
                <Text style={{ marginTop: 10, fontSize: 25, fontWeight: 'bold' }}>Users:</Text>
                {displayUsers()}
            </ScrollView>
        </View>
    );
}