import React, { Component, useState, useEffect, useContext, useRef, useCallback } from 'react';
import {
    View,
    SafeAreaView,
    Text,
    Alert,
    ScrollView,
    Image,
    Dimensions,
    TextInput,
    ActivityIndicator,
    useWindowDimensions,
} from 'react-native';
import { Button, Input, Card } from 'react-native-elements';
import styles from './styles';
import { Cell, Section, TableView } from 'react-native-tableview-simple';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { useFocusEffect } from '@react-navigation/native';
import { BUTTON_COLOR_ONE } from '../../../constants';


export default function Home() {

    const [plans, setPlans] = useState([])
    const [users, setUsers] = useState([])
    const [pageLoading, setPageLoading] = useState(true)

    useEffect(() => {
        const planSubscriber = firebase.firestore().collection('plans').onSnapshot(querySnapshot => {
            setPageLoading(true)
            const plansLocal = querySnapshot.docs.map(doc => doc)
            setPlans(plansLocal)
            setPageLoading(false)
        })
        const userSubscriber = firebase.firestore().collection('userProfiles').onSnapshot(querySnapshot => {
            setPageLoading(true)
            const usersLocal = querySnapshot.docs.map(doc => doc.data())
            setUsers(usersLocal)
            setPageLoading(false)
        })
        return function cleanup() {
            planSubscriber();
            userSubscriber()
        }
    }, [])

    const displayPlans = () => {
        const valueArray = []
        var index = 0
        plans.forEach((plan) => {
            const userId = plan.data().user
            const userObj = users.filter(user => user.uid === userId)
            const userName = (userObj.length > 0) ? (userObj[0].firstName + ' ' + userObj[0].lastName) : userId
            valueArray.push(<View key={index}><Text>User: {userName}, ID: {userId}, plan name: {plan.data().planName}, commitment period: {plan.data().planCommitmentPeriod}</Text><hr /></View>)
            index += 1
        })
        return (
            <View>
                {valueArray}
            </View>
        )
    }

    if (pageLoading) {
        return (
            <View>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView>
                <Text style={{ marginTop: 10, fontSize: 25, fontWeight: 'bold' }}>Plans:</Text>
                {displayPlans()}
            </ScrollView>
        </View>
    );
}