import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import {
    ActivityIndicator,
    View,
    ScrollView,
    Dimensions,
    TextInput,
    Text
} from 'react-native';
import { Cell, Section, TableView } from 'react-native-tableview-simple';
import { Button } from 'react-native-elements';
import styles from './styles';
import { CELL_BACKGROUND_COLOR, CELL_TITLE_TEXT_COLOR, RIGHT_DETAIL_TEXT_COLOR, DISABLED_TEXT_INPUT_COLOR, HEADER_TEXT_COLOR, PLACEHOLDER_TEXT_INPUT_COLOR, TEXT_INPUT_COLOR, BUTTON_COLOR_TWO, CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, DRY_CLEAN_PRICES } from '../../../constants';
import { HeaderBackButton } from '@react-navigation/stack';
import RNPickerSelect, { defaultStyles } from 'react-native-picker-select';
import SearchableDropdown from 'react-native-searchable-dropdown';
import AlertModal from '../AlertModal'


export default function CreateDeletePickups({ navigation }) {

    useLayoutEffect(() => {
        navigation.setOptions({
            headerLeft: () => (
                <HeaderBackButton
                    onPress={() => navigation.goBack()}
                    disabled={pageLoading}
                    tintColor={HEADER_TEXT_COLOR}
                />
            )
        })
    })

    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState([])
    const [searchText, setSearchText] = useState('')

    const [allPickupDropdownItems, setAllPickupDropdownItems] = useState()
    const [pickupDropdownItems, setPickupDropdownItems] = useState([])
    const [pickups, setPickups] = useState([])
    const [selectedPickupDropdownItem, setSelectedPickupDropdownItem] = useState(null)
    const [selectedPickup, setSelectedPickup] = useState(null)
    const [pageLoading, setPageLoading] = useState(true)
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }

    const deletePickup = async () => {
        closeAlertModal()
        setPageLoading(true)
        var deletePickup = firebase.functions().httpsCallable('deletePickup');
        try {
            console.log('ayy bro')
            const response = await deletePickup({
                pickupId: selectedPickup.id,
            })
            console.log('ayy bro.2')
            if (response.data && response.data.success) {
                navigation.navigate('Delete Pickups Confirmation', {
                    message: response.data.message,
                })
                setPageLoading(false)
            }
            else {
                console.log(response.data.message)
                setPageLoading(false)
                openAlertModal('Error', 'Sorry, there was an error purchasing your plan.  Please try again.  If this issue persists, please contact us.', 'Ok', closeAlertModal)
                return
            }
        }
        catch (err) {
            console.log('error:', err)
            setPageLoading(false)
            openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, closeAlertModal)
            return
        }
    }

    const deletePickupButton = async () => {
        if (selectedPickup) {
            openAlertModal('Confirm', 'Are you sure you want to delete this pickup?', 'Yes', deletePickup, 'No', closeAlertModal)
        }
        else {
            setPageLoading(false)
            openAlertModal('Error', 'Please select a pickup', 'Ok', closeAlertModal)
            return
        }
    }

    useEffect(() => {
        
        const userSubscriber = firebase.firestore().collection('userProfiles').onSnapshot(querySnapshot => {
            setPageLoading(true)
            const userDocs = querySnapshot.docs
            const usersLocal = []
            for (var i = 0; i < userDocs.length; i++) {
                const userInfo = userDocs[i].data()
                const userId = userInfo.uid
                const userName = userInfo.firstName + ' ' + userInfo.lastName
                usersLocal.push({
                    id: userId,
                    name: userName
                })
            }
            setUsers(usersLocal)
            setPageLoading(false)
        })

        const pickupSubscriber = firebase.firestore().collection('pickups').onSnapshot(querySnapshot => {
            setPageLoading(true)
            const pickupDocs = querySnapshot.docs
            pickupDocs.sort((a, b) => {
                return new Date(a.data().pickupDate) - new Date(b.data().pickupDate)
            })
            const pickupsLocal = []
            const pickupDropdownItemsLocal = []
            for (var i = 0; i < pickupDocs.length; i++) {
                if (!pickupDocs[i].data().paymentSuccessful) {
                    const label = pickupDocs[i].data().userFirstName + ' ' + pickupDocs[i].data().userLastName + ': ' + pickupDocs[i].data().pickupDay
                    pickupsLocal.push(pickupDocs[i])
                    pickupDropdownItemsLocal.push({
                        label: label,
                        value: pickupDocs[i].id,
                        key: i,
                        userId: pickupDocs[i].data().userId
                    })
                }
            }
            setPickups(pickupsLocal)
            setPickupDropdownItems(pickupDropdownItemsLocal)
            setAllPickupDropdownItems(pickupDropdownItemsLocal)
            setPageLoading(false)
        })

        return function cleanup() {
            userSubscriber()
            pickupSubscriber()
        }
    }, []);

    const selectPickup = (value) => {
        setSelectedPickupDropdownItem(value)
        var foundPickup = false
        pickups.forEach((pickup) => {
            if (pickup.id === value) {
                const pickupData = pickup.data()
                setSelectedPickup(pickup)
                foundPickup = true
            }
        })
        if (!foundPickup) {
            setSelectedPickup(null)
        }
    }

    const filterPickups = (userId, clear=false) => {
        if (clear) {
            setPickupDropdownItems(allPickupDropdownItems)
            return
        }
        const filteredPickupDropdownItems = allPickupDropdownItems.filter(item => item.userId == userId )
        setPickupDropdownItems(filteredPickupDropdownItems)
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView>
                <Section header="Select a User (optional)">
                    <SearchableDropdown
                        onItemSelect={(user) => {
                            setSelectedUser(user)
                            setSearchText(user.name)
                            filterPickups(user.id)
                            console.log('selectedUser:', user)
                        }}
                        containerStyle={{ padding: 5 }}
                        onRemoveItem={(item, index) => {
                            setSelectedUser(null)
                        }}
                        itemStyle={{
                            padding: 10,
                            marginTop: 2,
                            backgroundColor: '#ddd',
                            borderColor: '#bbb',
                            borderWidth: 1,
                            borderRadius: 5,
                        }}
                        itemTextStyle={{ color: '#222' }}
                        itemsContainerStyle={{ maxHeight: 140 }}
                        items={users}
                        defaultIndex={2}
                        resetValue={false}
                        textInputProps={
                            {
                                placeholder: "User Name",
                                underlineColorAndroid: "transparent",
                                style: {
                                    padding: 12,
                                    borderWidth: 1,
                                    borderColor: '#ccc',
                                    borderRadius: 5,
                                },
                                value: searchText,
                                onTextChange: (text) => {
                                    setSearchText(text)
                                    setSelectedUser(null)
                                    filterPickups(null, true)
                                }
                            }
                        }
                        listProps={
                            {
                                nestedScrollEnabled: true,
                            }
                        }
                    />
                </Section>
                <Section header="Select a Pickup">
                    <RNPickerSelect
                        placeholder={{
                            label: 'Select a pickup...',
                            value: 'null',
                        }}
                        items={pickupDropdownItems}
                        onValueChange={value => {
                            selectPickup(value)
                        }}
                        value={selectedPickupDropdownItem}
                    />
                </Section>
                <View style={{ height: 50, width: "100%", marginTop: 40, alignItems: 'center', marginBottom: 50 }}>
                    <View style={{ width: "70%", maxWidth: 250, minWidth: 200 }} >
                        <Button
                            onPress={deletePickupButton}
                            title='Delete Pickup'
                            buttonStyle={{
                                backgroundColor: BUTTON_COLOR_TWO
                            }}
                        />
                    </View>
                </View>
            </ScrollView>
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}