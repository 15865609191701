import React, { Component, useState, useEffect, useContext, useRef, useCallback, useLayoutEffect } from 'react';
import {
    View,
    SafeAreaView,
    Text,
    Alert,
    ScrollView,
    Image,
    Dimensions,
    TextInput,
    ActivityIndicator,
    useWindowDimensions,
} from 'react-native';
import { Button, Input, Card } from 'react-native-elements';
import styles from './styles';
import { Cell, Section, TableView } from 'react-native-tableview-simple';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { useFocusEffect } from '@react-navigation/native';
import { ACTIVITY_INDICATOR_COLOR_ONE, BACKGROUND_COLOR, BUTTON_COLOR_ONE, BUTTON_COLOR_TWO, CELL_BACKGROUND_COLOR, CELL_TITLE_TEXT_COLOR, HEADER_TEXT_COLOR, PLACEHOLDER_TEXT_INPUT_COLOR, SEPARATOR_TINT_COLOR_TWO, TEXT_COLOR_FOUR, TEXT_INPUT_COLOR } from '../../../constants';
import RNPickerSelect, { defaultStyles } from 'react-native-picker-select';
import DatePicker from 'react-date-picker'
import AlertModal from '../AlertModal'

export default function AddBookings({ navigation }) {

    const createBooking = async (addPickup) => {
        if (!userId || userId === '') {
            openAlertModal('Error', 'Please select a user.', 'Ok', closeAlertModal)
            return
        }
        if (!hotelName || hotelName === '') {
            openAlertModal('Error', 'Please enter a hotel name.', 'Ok', closeAlertModal)
            return
        }
        if (!hotelAddress || hotelAddress === '') {
            openAlertModal('Error', 'Please enter a hotel address.', 'Ok', closeAlertModal)
            return
        }
        if (!roomNumber || roomNumber === '') {
            openAlertModal('Error', 'Please enter a room number.', 'Ok', closeAlertModal)
            return
        }
        if (!checkoutDate) {
            openAlertModal('Error', 'Please select a checkout date.', 'Ok', closeAlertModal)
            return
        }
        const checkoutDateString = checkoutDate.toDateString()
        // add new booking
        await firebase.firestore().collection('hotelBookings').add({
            hotelName,
            hotelAddress,
            roomNumber,
            checkoutDate: checkoutDateString,
            userId
        })
        if (addPickup) {
            navigation.navigate('Add Hotel Pickup')
        }
        else {
            navigation.navigate('Hotel Bookings')
        }
    }

    useEffect(() => {
        const userSubscriber = firebase.firestore().collection('hotelUserProfiles').onSnapshot(querySnapshot => {
            setPageLoading(true)
            const usersLocal = querySnapshot.docs
            const userDropdownItemsLocal = []
            for (var i = 0; i < usersLocal.length; i++) {
                const userName = usersLocal[i].data().firstName + ' ' + usersLocal[i].data().lastName
                userDropdownItemsLocal.push({
                    label: userName,
                    value: usersLocal[i].id,
                    key: i
                })
            }
            setUserDropdownItems(userDropdownItemsLocal)
            setPageLoading(false)
        })
        const hotelSubscriber = firebase.firestore().collection('hotelInfo').onSnapshot(querySnapshot => {
            setPageLoading(true)
            const hotelsLocal = querySnapshot.docs
            const hotelDropdownItemsLocal = []
            const hotelListLocal = {}
            for (var i = 0; i < hotelsLocal.length; i++) {
                const hotelName = hotelsLocal[i].data().hotelName
                const hotelAddress = hotelsLocal[i].data().hotelAddress
                hotelListLocal[hotelName] = hotelAddress
                hotelDropdownItemsLocal.push({
                    label: hotelName,
                    value: hotelName,
                    key: i
                })
            }
            setHotelList(hotelListLocal)
            setHotelDropdownItems(hotelDropdownItemsLocal)
            setPageLoading(false)
        })
        return function cleanup() {
            userSubscriber();
        }
    }, [])
    
    const refs = {
        'hotelName': useRef(),
        'hotelAddress': useRef(),
        'roomNumber': useRef(),
        'checkoutDate': useRef(),
        'user': useRef()
    };

    const [pageLoading, setPageLoading] = useState(true)
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }

    const [hotelName, setHotelName] = useState('')
    const [hotelAddress, setHotelAddress] = useState('')
    const [roomNumber, setRoomNumber] = useState('')
    const [checkoutDate, setCheckoutDate] = useState('')

    const [userDropdownItems, setUserDropdownItems] = useState([])
    const [hotelDropdownItems, setHotelDropdownItems] = useState([])

    const [hotelList, setHotelList] = useState({})

    const [selectedUser, setSelectedUser] = useState(null)
    const [selectedHotel, setSelectedHotel] = useState(null)
    const [userId, setUserId] = useState('')

    const onSubmitHotelName = () => {
        refs['hotelAddress'].current.focus()
    }

    const onSubmitHotelAddress = () => {
        refs['roomNumber'].current.focus()
    }

    const onSubmitRoomNumber = () => {
        refs['firstName'].current.focus()
    }

    const onSubmitCheckoutDate = () => {
        refs['additionalInstructions'].current.focus()
    }

    const selectUser = (value) => {
        setSelectedUser(value)
        setUserId(value)
    }

    const selectHotel = (value) => {
        setSelectedHotel(value)
        setHotelName(value)
        setHotelAddress(hotelList[value])
    }

    if (pageLoading) {
        return (
            <View>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView>
                <Section header="User">
                    <RNPickerSelect
                        placeholder={{
                            label: 'Select a user...',
                            value: 'null',
                        }}
                        items={userDropdownItems}
                        onValueChange={value => {
                            selectUser(value)
                        }}
                        value={selectedUser}
                    />
                </Section>
                <Section header="Hotel">
                    <RNPickerSelect
                        placeholder={{
                            label: 'Select a hotel...',
                            value: 'null',
                        }}
                        items={hotelDropdownItems}
                        onValueChange={value => {
                            selectHotel(value)
                        }}
                        value={selectedHotel}
                    />
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="Hotel Address"
                            ref={refs["hotelAddress"]}
                            autoCorrect={false}
                            value={hotelAddress}
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                            editable={false}
                        />
                    } />
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="Room Number"
                            ref={refs["roomNumber"]}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => setRoomNumber(value)}
                            value={roomNumber}
                            onSubmitEditing={onSubmitRoomNumber}
                            returnKeyType="next"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                        />
                    } />
                </Section>
                <View style={{ zIndex: 1000 }}>
                    <Section header="Checkout Date">
                        <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                            <DatePicker onChange={setCheckoutDate} value={checkoutDate} style={{ zIndex: 10 }} disabled={pageLoading} />
                        } />
                    </Section>
                </View>
                <View style={{ height: 50, width: "100%", marginTop: 40, alignItems: 'center' }}>
                    <View style={{ width: "70%", maxWidth: 250, minWidth: 200 }} >
                        <Button
                            onPress={() => createBooking(false)}
                            title='Create New Booking'
                            buttonStyle={{
                                backgroundColor: BUTTON_COLOR_TWO
                            }}
                        />
                    </View>
                </View>
                <View style={{ height: 50, width: "100%", marginTop: 40, alignItems: 'center', marginBottom: 50 }}>
                    <View style={{ width: "70%", maxWidth: 250, minWidth: 200 }} >
                        <Button
                            onPress={() => createBooking(true)}
                            title='Create New Booking & Add Pickup'
                            buttonStyle={{
                                backgroundColor: BUTTON_COLOR_TWO
                            }}
                        />
                    </View>
                </View>
            </ScrollView>
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}