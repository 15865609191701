import React, { Component, useState, useEffect, useContext, useRef, useCallback } from 'react';
import {
    View,
    SafeAreaView,
    Text,
    Alert,
    ScrollView,
    Image,
    Dimensions,
    TextInput,
    ActivityIndicator,
    useWindowDimensions,
} from 'react-native';
import { Button, Input, Card } from 'react-native-elements';
import styles from './styles';
import { Cell, Section, TableView } from 'react-native-tableview-simple';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { useFocusEffect } from '@react-navigation/native';
import { BUTTON_COLOR_ONE, CELL_BACKGROUND_COLOR, CELL_TITLE_TEXT_COLOR, HEADER_TEXT_COLOR } from '../../../constants';
import TopHeading from '../../../TopHeading.js'


export default function Home({ navigation, route }) {

    const [pageLoading, setPageLoading] = useState(true)

    useEffect(() => {
        setPageLoading(false)
        return function cleanup() {
            
        }
    }, [])

    const logout = async () => {
        await firebase.auth().signOut();
    }

    const openPickups = () => {
        navigation.navigate('Pickups')
    }

    const openUsers = () => {
        navigation.navigate('Users')
    }

    const openPlans = () => {
        navigation.navigate('Plans')
    }

    const openProcessPayments = () => {
        navigation.navigate('Process Payments')
    }

    const openCreatePickups = () => {
        navigation.navigate('Create Pickups')
    }

    const openDeletePickups = () => {
        navigation.navigate('Delete Pickups')
    }

    if (pageLoading) {
        return (
            <View>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView style={{ flex: 1, width: "100%", marginLeft: "0%" }}>
                <View style={styles.imageContainer}>
                    <Image source={require("../../assets/Tumble_Logo.png")} style={{ width: 170, height: 170 }} />
                </View>
                <Text style={styles.heading}>Tumble Admin</Text>
                <Section header="View Info" headerTextColor={HEADER_TEXT_COLOR}>
                    <Cell cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} contentContainerStyle={{ height: 50 }} titleTextColor={CELL_TITLE_TEXT_COLOR} titleTextStyle={{ fontWeight: 'bold' }} title="Pickups" accessory="DisclosureIndicator" onPress={openPickups} />
                    <Cell cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} contentContainerStyle={{ height: 50 }} titleTextColor={CELL_TITLE_TEXT_COLOR} titleTextStyle={{ fontWeight: 'bold' }} title="Users" accessory="DisclosureIndicator" onPress={openUsers} />
                    <Cell cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} contentContainerStyle={{ height: 50 }} titleTextColor={CELL_TITLE_TEXT_COLOR} titleTextStyle={{ fontWeight: 'bold' }} title="Plans" accessory="DisclosureIndicator" onPress={openPlans} />
                </Section>
                <Section header="Pickups and Payments" headerTextColor={HEADER_TEXT_COLOR}>
                    <Cell cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} contentContainerStyle={{ height: 50 }} titleTextColor={CELL_TITLE_TEXT_COLOR} titleTextStyle={{ fontWeight: 'bold' }} title="Process Payments" accessory="DisclosureIndicator" onPress={openProcessPayments} />
                    <Cell cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} contentContainerStyle={{ height: 50 }} titleTextColor={CELL_TITLE_TEXT_COLOR} titleTextStyle={{ fontWeight: 'bold' }} title="Create Pickups" accessory="DisclosureIndicator" onPress={openCreatePickups} />
                    <Cell cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} contentContainerStyle={{ height: 50 }} titleTextColor={CELL_TITLE_TEXT_COLOR} titleTextStyle={{ fontWeight: 'bold' }} title="Delete Pickups" accessory="DisclosureIndicator" onPress={openDeletePickups} />
                </Section>
                <Section>
                    <Cell cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} contentContainerStyle={{ height: 50 }} titleTextColor={CELL_TITLE_TEXT_COLOR} titleTextStyle={{ fontWeight: 'bold' }} title="Logout" onPress={logout} />
                </Section>
            </ScrollView>
        </View>
    );
}