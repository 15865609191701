import React, { Component, useState, useEffect, useContext, useRef, useCallback, useLayoutEffect } from 'react';
import {
    View,
    SafeAreaView,
    Text,
    Alert,
    ScrollView,
    Image,
    Dimensions,
    TextInput,
    ActivityIndicator,
    useWindowDimensions,
} from 'react-native';
import { Button, Input, Card } from 'react-native-elements';
import styles from './styles';
import { Cell, Section, TableView } from 'react-native-tableview-simple';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { useFocusEffect } from '@react-navigation/native';
import { BUTTON_COLOR_ONE } from '../../../constants';
import { HeaderBackButton } from '@react-navigation/stack';


export default function HotelPickups({ navigation }) {

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => {
                return <Button
                        onPress={() => addHotelPickup()}
                        title="+"
                        type="clear"
                        titleStyle={{
                            fontSize: 30
                        }}
                        buttonStyle={{
                            marginRight: 10,
                        }}
                    />
            },
            headerLeft: () => {
                return <HeaderBackButton
                        label="Back"
                        onPress={() => navigation.navigate('Hotel')}
                    />
            }
        })
    })

    const addHotelPickup = () => {
        navigation.navigate('Add Hotel Pickup')
    }

    const [upcomingPickups, setUpcomingPickups] = useState([])
    const [pastPickups, setPastPickups] = useState([])
    const [pageLoading, setPageLoading] = useState(true)

    useEffect(() => {
        const pickupSubscriber = firebase.firestore()
            .collection('hotelPickups')
            .onSnapshot(querySnapshot => {
                setPageLoading(true)
                const pickupsLocal = querySnapshot.docs.map(doc => doc)
                const upcomingPickups = []
                let currentDate = new Date()
                for (var i = 0; i < pickupsLocal.length; i++) {
                    let pickupDate = new Date(pickupsLocal[i].data().pickupDate)
                    if (pickupDate >= currentDate) {
                        upcomingPickups.push(pickupsLocal[i])
                    }
                }
                upcomingPickups.sort((a, b) => {
                    return new Date(b.data().pickupDate) - new Date(a.data().pickupDate)
                })
                setUpcomingPickups(upcomingPickups);

                const pastPickups = []
                for (var i = 0; i < pickupsLocal.length; i++) {
                    let pickupDate = new Date(pickupsLocal[i].data().pickupDate)
                    if (pickupDate < currentDate) {
                        pastPickups.push(pickupsLocal[i])
                    }
                }
                pastPickups.sort((a, b) => {
                    return new Date(b.data().pickupDate) - new Date(a.data().pickupDate)
                })
                setPastPickups(pastPickups);
                setPageLoading(false)
            });
        return function cleanup() {
            pickupSubscriber();
        }
    }, [])

    const displayUpcomingPickups = () => {
        const valueArray = []
        var index = 0
        upcomingPickups.forEach((pickup) => {
            valueArray.push(
                <View key={index}>
                    <Text style={{ fontSize: 15 }}>
                        {pickup.data().pickupDate}: {pickup.data().firstName} {pickup.data().lastName} ({pickup.data().hotelName}: {pickup.data().hotelAddress}), {pickup.id}
                    </Text>
                    <Text style={{ marginLeft: 15 }}>
                        Total: {pickup.data().totalCost}
                    </Text>
                    <hr />
                </View>
            )
            index += 1
        })
        return (
            <View>
                {valueArray}
            </View>
        )
    }

    const displayPastPickups = () => {
        const valueArray = []
        var index = 0
        pastPickups.forEach((pickup) => {
            valueArray.push(
                <View key={index}>
                    <Text style={{ fontSize: 15 }}>
                        {pickup.data().pickupDate}: {pickup.data().firstName} {pickup.data().lastName} ({pickup.data().hotelName}: {pickup.data().hotelAddress}), {pickup.id}
                    </Text>
                    <Text style={{ marginLeft: 15 }}>
                        Total: {pickup.data().totalCost}
                    </Text>
                    <hr />
                </View>
            )
            index += 1
        })
        return (
            <View>
                {valueArray}
            </View>
        )
    }

    if (pageLoading) {
        return (
            <View>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView>
                <Text style={{ marginTop: 10, fontSize: 25, fontWeight: 'bold' }}>Upcoming Pickups:</Text>
                {displayUpcomingPickups()}
                <Text style={{ marginTop: 10, fontSize: 25, fontWeight: 'bold' }}>Past Pickups:</Text>
                {displayPastPickups()}
            </ScrollView>
        </View>
    );
}