import React, { Component, useState, useEffect, useContext, useRef, useCallback } from 'react';
import {
    View,
    SafeAreaView,
    Text,
    Alert,
    ScrollView,
    Image,
    Dimensions,
    TextInput,
    ActivityIndicator,
    useWindowDimensions,
} from 'react-native';
import { Button, Input, Card } from 'react-native-elements';
import styles from './styles';
import { Cell, Section, TableView } from 'react-native-tableview-simple';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { useFocusEffect } from '@react-navigation/native';
import { BUTTON_COLOR_ONE, CELL_BACKGROUND_COLOR, CELL_TITLE_TEXT_COLOR, HEADER_TEXT_COLOR } from '../../../constants';
import TopHeading from '../../../TopHeading.js'


export default function Home({ navigation, route }) {

    const [pageLoading, setPageLoading] = useState(true)

    useEffect(() => {
        setPageLoading(false)
        return function cleanup() {
            
        }
    }, [])

    const logout = async () => {
        await firebase.auth().signOut();
    }

    const openResidential = () => {
        navigation.navigate('Residential')
    }

    const openHotel = () => {
        navigation.navigate('Hotel')
    }

    if (pageLoading) {
        return (
            <View>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <View style={{ zIndex: 10 }}>
                <TopHeading navigation={navigation} currentPage="Our Services" />
            </View>
            <View style={{ flex: 1, marginTop: 50, zIndex: 0 }}>
                <ScrollView style={{ flex: 1, width: "100%", marginLeft: "0%" }}>
                    <View style={styles.imageContainer}>
                        <Image source={require("../../assets/Tumble_Logo.png")} style={{ width: 170, height: 170 }} />
                    </View>
                    <Text style={styles.heading}>Tumble Admin</Text>
                    <Section header="Residential" headerTextColor={HEADER_TEXT_COLOR}>
                        <Cell cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} contentContainerStyle={{ height: 50 }} titleTextColor={CELL_TITLE_TEXT_COLOR} titleTextStyle={{ fontWeight: 'bold' }} title="Residential" accessory="DisclosureIndicator" onPress={openResidential} />
                    </Section>
                    <Section header="Hotel" headerTextColor={HEADER_TEXT_COLOR}>
                        <Cell cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} contentContainerStyle={{ height: 50 }} titleTextColor={CELL_TITLE_TEXT_COLOR} titleTextStyle={{ fontWeight: 'bold' }} title="Hotel" accessory="DisclosureIndicator" onPress={openHotel} />
                    </Section>
                    <Section>
                        <Cell cellStyle="Basic" backgroundColor={CELL_BACKGROUND_COLOR} contentContainerStyle={{ height: 50 }} titleTextColor={CELL_TITLE_TEXT_COLOR} titleTextStyle={{ fontWeight: 'bold' }} title="Logout" onPress={logout} />
                    </Section>
                </ScrollView>
            </View>
        </View>
    );
}