import React, { Component, useState, useEffect, useContext, useRef, useCallback, useLayoutEffect, useReducer } from 'react';
import {
    View,
    SafeAreaView,
    Text,
    Alert,
    ScrollView,
    Image,
    Dimensions,
    TextInput,
    ActivityIndicator,
    useWindowDimensions,
} from 'react-native';
import { Button, Input, Card } from 'react-native-elements';
import styles from './styles';
import { Cell, Section, TableView } from 'react-native-tableview-simple';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { useFocusEffect } from '@react-navigation/native';
import { ACTIVITY_INDICATOR_COLOR_ONE, BACKGROUND_COLOR, BUTTON_COLOR_ONE, BUTTON_COLOR_TWO, CELL_BACKGROUND_COLOR, CELL_TITLE_TEXT_COLOR, HEADER_TEXT_COLOR, PLACEHOLDER_TEXT_INPUT_COLOR, SEPARATOR_TINT_COLOR_TWO, TEXT_COLOR_FOUR, TEXT_INPUT_COLOR } from '../../../constants';
import RNPickerSelect, { defaultStyles } from 'react-native-picker-select';
import DatePicker from 'react-date-picker'
import AlertModal from '../AlertModal'


export default function AddPickup({ navigation }) {

    const createPickup = async () => {
        if (!userId || userId === '') {
            openAlertModal('Error', 'Please select a user.', 'Ok', closeAlertModal)
            return
        }
        if (!bookingId || bookingId === '') {
            openAlertModal('Error', 'Please select a booking.', 'Ok', closeAlertModal)
            return
        }
        if (!pickupDate || pickupDate === '') {
            openAlertModal('Error', 'Please select a pickup date.', 'Ok', closeAlertModal)
            return
        }
        if (!deliveryDate || deliveryDate === '') {
            openAlertModal('Error', 'Please select a pickup date.', 'Ok', closeAlertModal)
            return
        }
        if (!pickupTime || pickupTime === '') {
            openAlertModal('Error', 'Please select a pickup time.', 'Ok', closeAlertModal)
            return
        }
        if (!totalCost || totalCost === '') {
            openAlertModal('Error', 'Please enter total cost.', 'Ok', closeAlertModal)
            return
        }
        if (!firstName || firstName === '') {
            openAlertModal('Error', 'Please enter first name.', 'Ok', closeAlertModal)
            return
        }
        if (!lastName || lastName === '') {
            openAlertModal('Error', 'Please enter last name.', 'Ok', closeAlertModal)
            return
        }
        if (!email || email === '') {
            openAlertModal('Error', 'Please enter email.', 'Ok', closeAlertModal)
            return
        }
        if (!phoneNumber || phoneNumber === '') {
            openAlertModal('Error', 'Please enter phone number.', 'Ok', closeAlertModal)
            return
        }
        if (!detergent || detergent === '') {
            openAlertModal('Error', 'Please enter detergent.', 'Ok', closeAlertModal)
            return
        }
        if (!dryerSheet || dryerSheet === '') {
            openAlertModal('Error', 'Please enter dryer sheet.', 'Ok', closeAlertModal)
            return
        }
        if (!fabricSoftener || fabricSoftener === '') {
            openAlertModal('Error', 'Please enter fabric softener.', 'Ok', closeAlertModal)
            return
        }
        if (!preferredContactMethod || preferredContactMethod === '') {
            openAlertModal('Error', 'Please enter preferred contact method.', 'Ok', closeAlertModal)
            return
        }
        if (!textReminders || textReminders === '') {
            openAlertModal('Error', 'Please enter text reminders.', 'Ok', closeAlertModal)
            return
        }
        if (!hotelName || hotelName === '') {
            openAlertModal('Error', 'Please enter a hotel name.', 'Ok', closeAlertModal)
            return
        }
        if (!hotelAddress || hotelAddress === '') {
            openAlertModal('Error', 'Please enter a hotel address.', 'Ok', closeAlertModal)
            return
        }
        if (!roomNumber || roomNumber === '') {
            openAlertModal('Error', 'Please enter a room number.', 'Ok', closeAlertModal)
            return
        }
        if (!checkoutDate) {
            openAlertModal('Error', 'Please select a checkout date.', 'Ok', closeAlertModal)
            return
        }
        const checkoutDateString = checkoutDate.toDateString()
        const pickupDateString = pickupDate.toDateString()
        const deliveryDateString = deliveryDate.toDateString()
        await firebase.firestore().collection('hotelPickups').add({
            hotelName,
            hotelAddress,
            roomNumber,
            firstName,
            lastName,
            email,
            phoneNumber,
            detergent,
            dryerSheet,
            fabricSoftener,
            preferredContactMethod,
            textReminders,
            checkoutDate: checkoutDateString,
            additionalInstructions,
            pickupDate: pickupDateString,
            deliveryDate: deliveryDateString,
            pickupTime,
            totalCost,
            additionalInstructions,
            userId,
            bookingId
        })
        navigation.navigate('Hotel Pickups')
    }

    const refs = {
        'hotelName': useRef(),
        'hotelAddress': useRef(),
        'roomNumber': useRef(),
        'firstName': useRef(),
        'lastName': useRef(),
        'email': useRef(),
        'phoneNumber': useRef(),
        'detergent': useRef(),
        'dryerSheet': useRef(),
        'fabricSoftener': useRef(),
        'preferredContactMethod': useRef(),
        'textReminders': useRef(),
        'checkoutDate': useRef(),
        'additionalInstructions': useRef(),
        'pickupDate': useRef(),
        'deliveryDate': useRef(),
        'pickupTime': useRef(),
        'totalCost': useRef(),
        'user': useRef(),
        'booking': useRef()
    };

    const [pageLoading, setPageLoading] = useState(true)
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }

    const [hotelName, setHotelName] = useState('')
    const [hotelAddress, setHotelAddress] = useState('')
    const [roomNumber, setRoomNumber] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [detergent, setDetergent] = useState('')
    const [dryerSheet, setDryerSheet] = useState('')
    const [fabricSoftener, setFabricSoftener] = useState('')
    const [preferredContactMethod, setPreferredContactMethod] = useState('')
    const [textReminders, setTextReminders] = useState('')
    const [checkoutDate, setCheckoutDate] = useState('')
    const [additionalInstructions, setAdditionalInstructions] = useState('')
    const [pickupDate, setPickupDate] = useState('')
    const [deliveryDate, setDeliveryDate] = useState('')
    const [pickupTime, setPickupTime] = useState('')
    const [totalCost, setTotalCost] = useState('')

    const [users, setUsers] = useState([])
    const [bookings, setBookings] = useState([])
    const [userId, setUserId] = useState('')
    const [bookingId, setBookingId] = useState('')
    const [selectedUser, setSelectedUser] = useState([])
    const [selectedBooking, setSelectedBooking] = useState([])
    const [userDropdownItems, setUserDropdownItems] = useState([])
    const [bookingsDropdownItems, setBookingDropdownItems] = useState([])

    useEffect(() => {
        const userSubscriber = firebase.firestore().collection('hotelUserProfiles').onSnapshot(querySnapshot => {
            setPageLoading(true)
            const usersLocal = querySnapshot.docs
            const userDropdownItemsLocal = []
            for (var i = 0; i < usersLocal.length; i++) {
                const userName = usersLocal[i].data().firstName + ' ' + usersLocal[i].data().lastName
                userDropdownItemsLocal.push({
                    label: userName,
                    value: usersLocal[i].id,
                    key: i
                })
            }
            setUsers(usersLocal)
            setUserDropdownItems(userDropdownItemsLocal)
            setPageLoading(false)
        })

        const bookingSubscriber = firebase.firestore().collection('hotelBookings').onSnapshot(querySnapshot => {
            setBookings(querySnapshot.docs)
        })

        return function cleanup() {
            userSubscriber();
            bookingSubscriber();
        }
    }, [])

    useEffect(() => {
        if (userId && userId !== '') {
            setPageLoading(true)
            // update the bookings dropdown
            const bookingDropdownItemsLocal = []
            for (var i = 0; i < bookings.length; i++) {
                if (bookings[i].data().userId === userId) {
                    const bookingDisplay = bookings[i].data().hotelName + ': ' + bookings[i].data().checkoutDate
                    bookingDropdownItemsLocal.push({
                        label: bookingDisplay,
                        value: bookings[i].id,
                        key: i
                    })
                }
            }
            setBookingDropdownItems(bookingDropdownItemsLocal)
            // update the user information
            for (var i = 0; i < users.length; i++) {
                if (users[i].id === userId) {
                    setFirstName(users[i].data().firstName)
                    setLastName(users[i].data().lastName)
                    setPhoneNumber(users[i].data().phoneNumber)
                    setPreferredContactMethod(users[i].data().preferredContactMethod)
                    setTextReminders(users[i].data().textReminders)
                    setDetergent(users[i].data().detergent)
                    setDryerSheet(users[i].data().dryerSheet)
                    setFabricSoftener(users[i].data().fabricSoftener)
                    setAdditionalInstructions(users[i].data().additionalInstructions)
                    setEmail(users[i].data().email)
                    break
                }
            }
            setPageLoading(false)
        }
    }, [userId, bookings])

    useEffect(() => {
        setPageLoading(true)
        for (var i = 0; i < bookings.length; i++) {
            if (bookings[i].id === bookingId) {
                setHotelName(bookings[i].data().hotelName)
                setHotelAddress(bookings[i].data().hotelAddress)
                setRoomNumber(bookings[i].data().roomNumber)
                const checkoutDateLocal = new Date(bookings[i].data().checkoutDate)
                console.log('checkoutDateLocal:', checkoutDateLocal)
                setCheckoutDate(checkoutDateLocal)
            }
        }
        setPageLoading(false)
    }, [bookingId])

    const selectUser = (value) => {
        setSelectedUser(value)
        setUserId(value)
    }

    const selectBooking = (value) => {
        setSelectedBooking(value)
        setBookingId(value)
    }

    const onSubmitFirstName = () => {
        refs['lastName'].current.focus()
    }

    const onSubmitLastName = () => {
        refs['email'].current.focus()
    }

    const onSubmitEmail = () => {
        refs['phoneNumber'].current.focus()
    }

    const onSubmitPhoneNumber = () => {
        refs['detergent'].current.focus()
    }

    const onSubmitDetergent = () => {
        refs['dryerSheet'].current.focus()
    }

    const onSubmitDryerSheet = () => {
        refs['fabricSoftener'].current.focus()
    }

    const onSubmitFabricSoftener = () => {
        refs['preferredContactMethod'].current.focus()
    }

    const onSubmitPreferredContactMethod = () => {
        refs['textReminders'].current.focus()
    }

    const onSubmitTextReminders = () => {
        refs['hotelName'].current.focus()
    }

    const onSubmitHotelName = () => {
        refs['hotelAddress'].current.focus()
    }

    const onSubmitHotelAddress = () => {
        refs['roomNumber'].current.focus()
    }

    const onSubmitRoomNumber = () => {
        refs['checkoutDate'].current.focus()
    }

    const onSubmitCheckoutDate = () => {
        refs['additionalInstructions'].current.focus()
    }

    const onSubmitAdditionalInstructions = () => {
        refs['pickupDate'].current.focus()
    }

    const onSubmitPickupDate = () => {
        refs['deliveryDate'].current.focus()
    }

    const onSubmitDeliveryDate = () => {
        refs['pickupTime'].current.focus()
    }

    const onSubmitPickupTime = () => {
        refs['totalCost'].current.focus()
    }

    const onSubmitTotalCost = () => {
        createPickup()
    }

    const selectPickupDate = (date) => {
        setPickupDate(date)
        const newDate = new Date(date.getTime())
        newDate.setDate(newDate.getDate() + 2)
        setDeliveryDate(newDate)
    }

    if (pageLoading) {
        return (
            <View>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView>
                <Section header="User">
                    <RNPickerSelect
                        placeholder={{
                            label: 'Select a user...',
                            value: 'null',
                        }}
                        items={userDropdownItems}
                        onValueChange={value => {
                            selectUser(value)
                        }}
                        value={selectedUser}
                    />
                </Section>
                <Section header="Booking">
                    <RNPickerSelect
                        placeholder={{
                            label: 'Select a booking...',
                            value: 'null',
                        }}
                        items={bookingsDropdownItems}
                        onValueChange={value => {
                            selectBooking(value)
                        }}
                        value={selectedBooking}
                    />
                </Section>
                <View style={{ zIndex: 1000 }}>
                    <Section header="Pickup Date">
                        <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                            <DatePicker onChange={selectPickupDate} value={pickupDate} style={{ zIndex: 100 }} disabled={pageLoading} />
                        } />
                    </Section>
                </View>
                <View style={{ zIndex: 999 }}>
                    <Section header="Estimated Delivery Date">
                        <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                            <DatePicker onChange={setDeliveryDate} value={deliveryDate} style={{ zIndex: 100 }} disabled={pageLoading} />
                        } />
                    </Section>
                </View>
                <Section header="Pickup Info">
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="Pickup Time"
                            ref={refs["pickupTime"]}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => setPickupTime(value)}
                            value={pickupTime}
                            onSubmitEditing={onSubmitPickupTime}
                            returnKeyType="next"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                        />
                    } />
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="Total Cost"
                            ref={refs["totalCost"]}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => setTotalCost(value)}
                            value={totalCost}
                            onSubmitEditing={onSubmitTotalCost}
                            returnKeyType="done"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                        />
                    } />
                </Section>
                <Section header="User Info">
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="First Name"
                            ref={refs["firstName"]}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => setFirstName(value)}
                            value={firstName}
                            onSubmitEditing={onSubmitFirstName}
                            returnKeyType="next"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                        />
                    } />
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="Last Name"
                            ref={refs["lastName"]}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => setLastName(value)}
                            value={lastName}
                            onSubmitEditing={onSubmitLastName}
                            returnKeyType="next"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                        />
                    } />
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="Email"
                            ref={refs["email"]}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => setEmail(value)}
                            value={email}
                            onSubmitEditing={onSubmitEmail}
                            returnKeyType="next"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                        />
                    } />
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="Phone Number"
                            ref={refs["phoneNumber"]}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => setPhoneNumber(value)}
                            value={phoneNumber}
                            onSubmitEditing={onSubmitPhoneNumber}
                            returnKeyType="next"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                        />
                    } />
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="Detergent"
                            ref={refs["detergent"]}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => setDetergent(value)}
                            value={detergent}
                            onSubmitEditing={onSubmitDetergent}
                            returnKeyType="next"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                        />
                    } />
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="Dryer Sheet"
                            ref={refs["dryerSheet"]}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => setDryerSheet(value)}
                            value={dryerSheet}
                            onSubmitEditing={onSubmitDryerSheet}
                            returnKeyType="next"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                        />
                    } />
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="Fabric Softener"
                            ref={refs["fabricSoftener"]}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => setFabricSoftener(value)}
                            value={fabricSoftener}
                            onSubmitEditing={onSubmitFabricSoftener}
                            returnKeyType="next"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                        />
                    } />
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="Preferred Contact Method (call/text/email)"
                            ref={refs["preferredContactMethod"]}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => setPreferredContactMethod(value)}
                            value={preferredContactMethod}
                            onSubmitEditing={onSubmitPreferredContactMethod}
                            returnKeyType="next"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                        />
                    } />
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="Text Reminders (true/false)"
                            ref={refs["textReminders"]}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => setTextReminders(value)}
                            value={textReminders}
                            onSubmitEditing={onSubmitTextReminders}
                            returnKeyType="next"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                        />
                    } />
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="Additional Instructions"
                            ref={refs["additionalInstructions"]}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => setAdditionalInstructions(value)}
                            value={additionalInstructions}
                            onSubmitEditing={onSubmitAdditionalInstructions}
                            returnKeyType="next"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                        />
                    } />
                </Section>
                <Section header="Booking Info">
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="Hotel Name"
                            ref={refs["hotelName"]}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => setHotelName(value)}
                            value={hotelName}
                            onSubmitEditing={onSubmitHotelName}
                            returnKeyType="next"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                        />
                    } />
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="Hotel Address"
                            ref={refs["hotelAddress"]}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => setHotelAddress(value)}
                            value={hotelAddress}
                            onSubmitEditing={onSubmitHotelAddress}
                            returnKeyType="next"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                        />
                    } />
                    <View style={{ zIndex: 1000 }}>
                        <Section header="Checkout Date">
                            <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                                <DatePicker onChange={setCheckoutDate} value={checkoutDate} style={{ zIndex: 100 }} disabled={pageLoading} />
                            } />
                        </Section>
                    </View>
                </Section>
                <View style={{ height: 50, width: "100%", marginTop: 40, alignItems: 'center', marginBottom: 50 }}>
                    <View style={{ width: "70%", maxWidth: 250, minWidth: 200 }} >
                        <Button
                            onPress={createPickup}
                            title='Create New Pickup'
                            buttonStyle={{
                                backgroundColor: BUTTON_COLOR_TWO
                            }}
                        />
                    </View>
                </View>
            </ScrollView>
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}