import React, { Component, useState, useEffect, useContext, useRef, useCallback, useLayoutEffect } from 'react';
import {
    View,
    SafeAreaView,
    Text,
    Alert,
    ScrollView,
    Image,
    Dimensions,
    TextInput,
    ActivityIndicator,
    useWindowDimensions,
} from 'react-native';
import { Button, Input, Card } from 'react-native-elements';
import styles from './styles';
import { Cell, Section, TableView } from 'react-native-tableview-simple';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { useFocusEffect } from '@react-navigation/native';
import { BUTTON_COLOR_ONE } from '../../../constants';
import { HeaderBackButton } from '@react-navigation/stack';


export default function HotelBookings({ navigation }) {

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => {
                return <Button
                        onPress={() => addHotelBooking()}
                        title="+"
                        type="clear"
                        titleStyle={{
                            fontSize: 30
                        }}
                        buttonStyle={{
                            marginRight: 10,
                        }}
                    />
            },
            headerLeft: () => {
                return <HeaderBackButton
                        label="Back"
                        onPress={() => navigation.navigate('Hotel')}
                    />
            }
        })
    })

    const addHotelBooking = () => {
        navigation.navigate('Add Hotel Booking')
    }

    const [bookings, setBookings] = useState([])
    const [users, setUsers] = useState([])
    const [pageLoading, setPageLoading] = useState(true)

    useEffect(() => {
        const bookingSubscriber = firebase.firestore()
            .collection('hotelBookings')
            .onSnapshot(querySnapshot => {
                setPageLoading(true)
                setBookings(querySnapshot.docs);
                setPageLoading(false)
            });
        
        const userSubscriber = firebase.firestore().collection('hotelUserProfiles').onSnapshot(querySnapshot => {
            setPageLoading(true)
            const usersLocal = querySnapshot.docs
            setUsers(usersLocal)
            setPageLoading(false)
        })
        return function cleanup() {
            bookingSubscriber();
            userSubscriber();
        }
    }, [])

    const displayBookings = () => {
        if (users.length > 0 && bookings.length > 0) {
            const valueArray = []
            var index = 0
            bookings.forEach((booking) => {
                const userId = booking.data().userId
                const userObj = users.filter(user => user.id === userId)
                valueArray.push(
                    <View key={index}>
                        <Text style={{ fontSize: 15 }}>
                            {userObj[0].data().firstName} {userObj[0].data().lastName}, {booking.data().hotelName}, {booking.data().checkoutDate}, {booking.data().hotelAddress}, {booking.data().roomNumber}
                        </Text>
                        <hr />
                    </View>
                )
                index += 1
            })
            return (
                <View>
                    {valueArray}
                </View>
            )
        }
        else {
            return <View></View>
        }
    }

    if (pageLoading) {
        return (
            <View>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView>
                {displayBookings()}
            </ScrollView>
        </View>
    );
}