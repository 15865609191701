import React, { Component, useState, useEffect, useContext, useRef, useCallback } from 'react';
import {
    View,
    SafeAreaView,
    Text,
    Alert,
    ScrollView,
    Image,
    Dimensions,
    TextInput,
    ActivityIndicator,
    useWindowDimensions,
} from 'react-native';
import { Button, Input, Card } from 'react-native-elements';
import styles from './styles';
import { Cell, Section, TableView } from 'react-native-tableview-simple';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { useFocusEffect } from '@react-navigation/native';
import { BUTTON_COLOR_ONE, CELL_BACKGROUND_COLOR, CELL_TITLE_TEXT_COLOR, HEADER_TEXT_COLOR } from '../../../constants';
import TopHeading from '../../../TopHeading.js'


export default function Home({ navigation, route }) {

    const [pageLoading, setPageLoading] = useState(true)

    useEffect(() => {
        setPageLoading(false)
        return function cleanup() {
            
        }
    }, [])

    const logout = async () => {
        await firebase.auth().signOut();
    }

    const openPickups = () => {
        navigation.navigate('Pickups')
    }

    const openUsers = () => {
        navigation.navigate('Users')
    }

    const openPlans = () => {
        navigation.navigate('Plans')
    }

    const openHotelPickups = () => {
        navigation.navigate('Hotel Pickups')
    }

    const openHotelUsers = () => {
        navigation.navigate('Hotel Users')
    }

    const openHotelBookings = () => {
        navigation.navigate('Hotel Bookings')
    }

    if (pageLoading) {
        return (
            <View>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <View style={{ zIndex: 10 }}>
                <TopHeading navigation={navigation} currentPage="Home" />
            </View>
            <View style={{ flex: 1, marginTop: 50, zIndex: 0 }}>
                <ScrollView style={{ flex: 1, width: "100%", marginLeft: "0%" }}>
                    <View style={styles.imageContainer}>
                        <Image source={require("../../assets/Tumble_Logo.png")} style={{ width: 170, height: 170 }} />
                    </View>
                    <Text style={styles.heading}>Tumble Admin</Text>
                </ScrollView>
            </View>
        </View>
    );
}