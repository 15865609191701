import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect, useMemo } from 'react';
import { createStackNavigator } from '@react-navigation/stack'
import { NavigationContainer } from '@react-navigation/native'
import { StyleSheet, Text, View, ActivityIndicator, Image, useWindowDimensions } from 'react-native';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import Home from './app/components/Home';
import Pickups from './app/components/Pickups'
import Users from './app/components/Users'
import Plans from './app/components/Plans'
import HotelPickups from './app/components/HotelPickups'
import HotelUsers from './app/components/HotelUsers'
import HotelBookings from './app/components/HotelBookings/index';
import AddHotelUser from './app/components/HotelUsers/AddUser'
import AddHotelPickup from './app/components/HotelPickups/AddPickup'
import AddHotelBooking from './app/components/HotelBookings/AddBooking'
import OurServices from './app/components/OurServices'
import Residential from './app/components/OurServices/Residential'
import Hotel from './app/components/OurServices/Hotel'
import MyCard from './app/components/MyCard'
import Login from './app/components/Login';
import ProcessPayments from './app/components/ProcessPayments'
import PaymentConfirmation from './app/components/ProcessPayments/Confirmation'
import CreatePickups from './app/components/CreateDeletePickups/Create';
import CreatePickupsConfirmation from './app/components/CreateDeletePickups/CreateConfirmation';
import DeletePickups from './app/components/CreateDeletePickups/Delete'
import DeletePickupsConfirmation from './app/components/CreateDeletePickups/DeleteConfirmation'
import { BACKGROUND_COLOR, HEADER_TEXT_COLOR, DEV } from './constants'
import AuthContext from './AuthContext'
import HotelWashCycle from './app/components/HotelWashCycle';

const firebaseConfig = {
  apiKey: "AIzaSyANOqj0nWGiQcqgaeoNxPG6vcuWmoPJMjQ",
  authDomain: "tumbledelivery-45056.firebaseapp.com",
  databaseURL: "https://tumbledelivery-45056.firebaseio.com",
  projectId: "tumbledelivery-45056",
  storageBucket: "tumbledelivery-45056.appspot.com",
  messagingSenderId: "701924652329",
  appId: "1:701924652329:web:96ee2cdb03d41bb2bab338",
  measurementId: "G-WHNZ7XB5MB"
}

firebase.initializeApp(firebaseConfig)
if (DEV) {
    const origin = "localhost";
    firebase.auth().useEmulator(`http://${origin}:9099/`)
    firebase.firestore().useEmulator(origin, 8080)
    firebase.functions().useEmulator(origin, 5001)
}

const Stack = createStackNavigator();

export default function App() {
  let widthPercent = "70%";
  let marginPercent = "15%";
  const dimensions = useWindowDimensions();
  if (dimensions.width >= 1100) {
    widthPercent = 1000;
    marginPercent = (dimensions.width - 1000) / 2;
  }
  else if ((dimensions.width >= 500) && (dimensions.width < 1200)) {
    widthPercent = "90%";
    marginPercent = "5%";
  }
  else {
    widthPercent = "95%";
    marginPercent = "2.5%"
  }

  const [pageLoading, setPageLoading] = useState(true)
  const [authenticated, setAuthenticated] = useState(false)
  const [adminAuthorized, setAdminAuthorized] = useState(false)
  const authContext = useMemo(() => ({

  }), []);

  useEffect(() => {
    const authSubscriber = firebase.auth().onAuthStateChanged(async (user) => {
      setPageLoading(true)
      if (user) {
        const adminUserInfo = (await firebase.firestore().collection('adminUserProfiles').doc(user.uid).get()).data()
        if (adminUserInfo) {
          setAuthenticated(true)
          setAdminAuthorized(true)
          setPageLoading(false)
        }
        else {
          setAuthenticated(false)
          setAdminAuthorized(false)
          setPageLoading(false)
        }
      }
      else {
        setAuthenticated(false)
        setAdminAuthorized(false)
        setPageLoading(false)
      }
    })
    return function cleanup() {
      authSubscriber();
    }
  }, [])

  if (pageLoading) {
    return (
      <View>
        <ActivityIndicator />
      </View>
    )
  }

  if (authenticated) {
    return (
      <View style={{ flex: 1, backgroundColor: BACKGROUND_COLOR }}>
        <Image source="https://cdn.shopify.com/s/files/1/1368/1329/files/HANGING-3-0841.jpg?v=1535396014" style={{ width: "100%", height: "100%", zIndex: 1, position: "absolute" }} />
        {/* <View style={{ width:widthPercent, height: "0%", marginLeft:marginPercent, zIndex: 3, backgroundColor:"#DCDCDC", alignItems:"center" }}>
            <Text style={{ fontSize:50 }}>Tumble Delivery</Text>
        </View> */}
        <View style={{ backgroundColor: 'rgba(20, 21, 24, .0)', zIndex: 1, position: "absolute", flex: 1, height: "100%", width: "100%" }}>
          <View style={{ width: widthPercent, height: "100%", marginLeft: marginPercent, zIndex: 3, backgroundColor: 'transparent', position: "absolute" }}>
            {/* <View style={{ width:"100%", height:"100%" }}> */}
            <AuthContext.Provider value={authContext}>
              <NavigationContainer>
                {
                  <Stack.Navigator screenOptions={{ cardStyle: { backgroundColor: 'rgba(20, 21, 24, .0)' } }}>
                    <Stack.Screen name="Home" component={Home} options={{ headerShown: false, animationEnabled: false, }} />
                    <Stack.Screen name="Our Services" component={OurServices} options={{ headerShown: false, animationEnabled: false, }} />
                    <Stack.Screen name="Residential" component={Residential} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Residential", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                    <Stack.Screen name="Hotel" component={Hotel} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Hotel", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                    <Stack.Screen name="My Card" component={MyCard} options={{ headerShown: false, animationEnabled: false, }} />
                    <Stack.Screen name="Pickups" component={Pickups} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Pickups", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                    <Stack.Screen name="Users" component={Users} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Users", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                    <Stack.Screen name="Plans" component={Plans} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Plans", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                    <Stack.Screen name="Hotel Pickups" component={HotelPickups} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Hotel Pickups", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                    <Stack.Screen name="Hotel Users" component={HotelUsers} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Hotel Users", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                    <Stack.Screen name="Hotel Bookings" component={HotelBookings} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Hotel Bookings", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                    <Stack.Screen name="Hotel Wash Cycle" component={HotelWashCycle} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Hotel Wash Cycle", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                    <Stack.Screen name="Add Hotel User" component={AddHotelUser} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Add Hotel User", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                    <Stack.Screen name="Add Hotel Pickup" component={AddHotelPickup} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Add Hotel Pickup", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                    <Stack.Screen name="Add Hotel Booking" component={AddHotelBooking} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Add Hotel Booking", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                    <Stack.Screen name="Process Payments" component={ProcessPayments} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Process Payments", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                    <Stack.Screen name="Payment Confirmation" component={PaymentConfirmation} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Payment Confirmation", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                    <Stack.Screen name="Create Pickups" component={CreatePickups} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Create Pickup", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                    <Stack.Screen name="Create Pickups Confirmation" component={CreatePickupsConfirmation} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Create Pickup Confirmation", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                    <Stack.Screen name="Delete Pickups" component={DeletePickups} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Delete Pickups", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                    <Stack.Screen name="Delete Pickups Confirmation" component={DeletePickupsConfirmation} options={{ headerShown: true, animationEnabled: false, headerStyle: { backgroundColor: BACKGROUND_COLOR }, headerTitle: "Delete Pickup Confirmation", headerTintColor: HEADER_TEXT_COLOR, headerTitleAlign: "center", }} />
                  </Stack.Navigator>
                }
              </NavigationContainer>
            </AuthContext.Provider>
          </View>
        </View>
      </View>
    )
  }
  else {
    return (
      <View style={{ flex: 1, backgroundColor: BACKGROUND_COLOR }}>
        <Image source="https://cdn.shopify.com/s/files/1/1368/1329/files/HANGING-3-0841.jpg?v=1535396014" style={{ width: "100%", height: "100%", zIndex: 1, position: "absolute" }} />
        {/* <View style={{ width:widthPercent, height: "0%", marginLeft:marginPercent, zIndex: 3, backgroundColor:"#DCDCDC", alignItems:"center" }}>
              <Text style={{ fontSize:50 }}>Tumble Delivery</Text>
          </View> */}
        <View style={{ width: "100%", height: "100%", zIndex: 3, backgroundColor: 'transparent', position: "absolute" }}>
          {/* <View style={{ width:"100%", height:"100%" }}> */}
          <AuthContext.Provider value={authContext}>
            <NavigationContainer>
              <Stack.Navigator>
                <Stack.Screen name="Login" component={Login} options={{ headerShown: false, title: "Login", cardStyle: { backgroundColor: 'rgba(20, 21, 24, .0)' } }} />
              </Stack.Navigator>
            </NavigationContainer>
          </AuthContext.Provider>
        </View>
      </View>
    )
  }
}
