import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import {
    ActivityIndicator,
    View,
    ScrollView,
    Dimensions,
    TextInput,
    Text,
    Switch
} from 'react-native';
import { Cell, Section, TableView } from 'react-native-tableview-simple';
import { Button } from 'react-native-elements';
import styles from './styles';
import { SEPARATOR_TINT_COLOR_TWO, FOOTER_TEXT_COLOR, BACKGROUND_COLOR, CELL_BACKGROUND_COLOR, CELL_TITLE_TEXT_COLOR, RIGHT_DETAIL_TEXT_COLOR, DISABLED_TEXT_INPUT_COLOR, HEADER_TEXT_COLOR, PLACEHOLDER_TEXT_INPUT_COLOR, TEXT_INPUT_COLOR, BUTTON_COLOR_TWO, CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, DRY_CLEAN_PRICES } from '../../../constants';
import { HeaderBackButton } from '@react-navigation/stack';
import RNPickerSelect, { defaultStyles } from 'react-native-picker-select';
import DatePicker from 'react-date-picker'
import SearchableDropdown from 'react-native-searchable-dropdown';
import AlertModal from '../AlertModal'


export default function CreateDeletePickups({ navigation }) {

    useLayoutEffect(() => {
        navigation.setOptions({
            headerLeft: () => (
                <HeaderBackButton
                    onPress={() => navigation.goBack()}
                    disabled={pageLoading}
                    tintColor={HEADER_TEXT_COLOR}
                />
            )
        })
    })

    const onSubmitNumberOfPounds = () => {
        // processPayment()
    }

    const refs = {
        "pounds": useRef(),
    }
    
    const getPageAndUserInfo = async () => {
        try {
            const pickupDateDoc = await firebase.firestore().collection('residentialInfo').doc('PickupSchedule').get()
            var pickupTimes = []
            var timeAccessoryLocal = {}
            if (pickupDateDoc.data() && pickupDateDoc.data().times) {
                pickupTimes = pickupDateDoc.data().times
                var index = 0
                pickupDateDoc.data().times.forEach((time) => {
                    if (index === 0) {
                        setTime(time)
                        timeAccessoryLocal[time] = "Checkmark"
                        index++
                    }
                    else {
                        timeAccessoryLocal[time] = "none"
                    }
                })
                setTimeAccessory(timeAccessoryLocal)
                setTimes(pickupTimes)
            }
            else {
                setPageLoading(false)
                openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, cancelPickup)
                return
            }
            setPageLoading(false)
        }
        catch (err) {
            console.log('error:', err)
            setPageLoading(false)
            openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, cancelPickup)
            return
        }
    }

    const [date, setDate] = useState(new Date(Date.now() + (1000 * 60 * 60 * 24)))
    const [time, setTime] = useState("")
    const [times, setTimes] = useState([])
    const [timeAccessory, setTimeAccessory] = useState({})

    const [washAndFoldPickup, setWashAndFoldPickup] = useState(true) // wash and fold by default
    const [dryCleaningPickup, setDryCleaningPickup] = useState(false)
    const [pickupDay, setPickupDay] = useState()
    const [pickupTime, setPickupTime] = useState()
    const [pickupDate, setPickupDate] = useState()
    const [userId, setUserId] = useState()

    const [selectedUser, setSelectedUser] = useState([])

    const [users, setUsers] = useState([])
    const [searchText, setSearchText] = useState('')

    const [pageLoading, setPageLoading] = useState(true)
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }

    useEffect(() => {
        
        const userSubscriber = firebase.firestore().collection('userProfiles').onSnapshot(querySnapshot => {
            setPageLoading(true)
            const userDocs = querySnapshot.docs
            const usersLocal = []
            for (var i = 0; i < userDocs.length; i++) {
                const userInfo = userDocs[i].data()
                const userId = userInfo.uid
                const userName = userInfo.firstName + ' ' + userInfo.lastName
                usersLocal.push({
                    id: userId,
                    name: userName
                })
            }
            setUsers(usersLocal)
            getPageAndUserInfo()
            setPageLoading(false)
        })

        return function cleanup() {
            userSubscriber()
        }
    }, []);

    const selectTime = async (selectedTime, accessoryParam = null) => {
        var accessoryLocal = accessoryParam
        if (!accessoryLocal) {
            accessoryLocal = timeAccessory
        }
        setTime(selectedTime)
        for (var key in accessoryLocal) {
            if (key == selectedTime) {
                accessoryLocal[key] = "Checkmark"
            }
            else {
                accessoryLocal[key] = "none"
            }
        }
        setTimeAccessory(accessoryLocal)
    }

    const createPickupTimeTable = () => {
        var valueArray = []
        var index = 0
        times.forEach((time) => {
            valueArray.push(
                <Cell key={index} backgroundColor={CELL_BACKGROUND_COLOR} cellStyle="Basic" titleTextColor={CELL_TITLE_TEXT_COLOR} title={time} accessory={timeAccessory[time]} isDisabled={pageLoading} onPress={() => selectTime(time)} />
            )
            index++
        })
        return valueArray
    }
    
    const toggleWashAndFold = () => {
        setWashAndFoldPickup(previousState => !previousState)
    }

    const toggleDryCleaning = () => {
        setDryCleaningPickup(previousState => !previousState)
    }

    const createPickup = async () => {
        setPageLoading(true)
        if (!userId) {
            setPageLoading(false)
            openAlertModal('Error', 'Please select a user', 'Ok', closeAlertModal)
            return
        }
        if (!dryCleaningPickup && !washAndFoldPickup) {
            setPageLoading(false)
            openAlertModal('Error', 'Please select either wash and fold, dry cleaning, or both', 'Ok', closeAlertModal)
            return
        }
        var createNewPickup = firebase.functions().httpsCallable('createNewPickup');
        try {
            const pickupDayLocal = date.toDateString()
            const pickupDateLocal = date.toDateString() + " 8:00"
            const pickupTimeLocal = "8 AM - 1 PM"
            const pickupData = {
                washAndFoldPickup: washAndFoldPickup,
                dryCleaningPickup: dryCleaningPickup,
                pickupDay: pickupDayLocal,
                pickupTime: pickupTimeLocal,
                pickupDate: pickupDateLocal
            }
            const response = await createNewPickup({
                pickup: pickupData,
                userId: userId
            })
            console.log(response.data)
            if (response.data && response.data.success) {
                navigation.navigate('Create Pickups Confirmation', {
                    message: response.data.message,
                    orderSummaryFields: response.data.orderSummaryFields
                })
                setPageLoading(false)
            }
            else {
                console.log(response.data.message)
                setPageLoading(false)
                openAlertModal('Error', 'Sorry, there was an error purchasing your plan.  Please try again.  If this issue persists, please contact us.', 'Ok', closeAlertModal)
                return
            }
        }
        catch (err) {
            console.log('error:', err)
            setPageLoading(false)
            openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, closeAlertModal)
            return
        }
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView>
                <Section header="Select a User (optional)">
                    <SearchableDropdown
                        onItemSelect={(user) => {
                            setSelectedUser(user)
                            setSearchText(user.name)
                            setUserId(user.id)
                            console.log('selectedUser:', user)
                        }}
                        containerStyle={{ padding: 5 }}
                        onRemoveItem={(item, index) => {
                            setSelectedUser(null)
                        }}
                        itemStyle={{
                            padding: 10,
                            marginTop: 2,
                            backgroundColor: '#ddd',
                            borderColor: '#bbb',
                            borderWidth: 1,
                            borderRadius: 5,
                        }}
                        itemTextStyle={{ color: '#222' }}
                        itemsContainerStyle={{ maxHeight: 140 }}
                        items={users}
                        defaultIndex={2}
                        resetValue={false}
                        textInputProps={
                            {
                                placeholder: "User Name",
                                underlineColorAndroid: "transparent",
                                style: {
                                    padding: 12,
                                    borderWidth: 1,
                                    borderColor: '#ccc',
                                    borderRadius: 5,
                                },
                                value: searchText,
                                onTextChange: (text) => {
                                    setSearchText(text)
                                    setSelectedUser(null)
                                }
                            }
                        }
                        listProps={
                            {
                                nestedScrollEnabled: true,
                            }
                        }
                    />
                </Section>
                <Section headerTextColor={HEADER_TEXT_COLOR} header="PICKUP DAY" footer="">
                </Section>
                <DatePicker onChange={setDate} value={date} style={{ zIndex: 10 }} disabled={pageLoading} />
                <Section headerTextColor={HEADER_TEXT_COLOR} header="PICKUP TIME" footer="">
                    {createPickupTimeTable()}
                </Section>
                <Section separatorTintColor={SEPARATOR_TINT_COLOR_TWO} header="" headerTextColor={HEADER_TEXT_COLOR} footerTextColor={FOOTER_TEXT_COLOR} footer="">
                    <Cell backgroundColor={BACKGROUND_COLOR} title="Wash and Fold" titleTextColor={CELL_TITLE_TEXT_COLOR} cellAccessoryView={
                        <Switch
                            trackColor={{ false: "#767577", true: "#81b0ff" }}
                            thumbColor={washAndFoldPickup ? "#f5dd4b" : "#f4f3f4"}
                            ios_backgroundColor="#3e3e3e"
                            onValueChange={toggleWashAndFold}
                            value={washAndFoldPickup}
                        />
                    } />
                </Section>
                <Section separatorTintColor={SEPARATOR_TINT_COLOR_TWO} header="" headerTextColor={HEADER_TEXT_COLOR} footerTextColor={FOOTER_TEXT_COLOR} footer="">
                    <Cell backgroundColor={BACKGROUND_COLOR} title="Dry Cleaning" titleTextColor={CELL_TITLE_TEXT_COLOR} cellAccessoryView={
                        <Switch
                            trackColor={{ false: "#767577", true: "#81b0ff" }}
                            thumbColor={dryCleaningPickup ? "#f5dd4b" : "#f4f3f4"}
                            ios_backgroundColor="#3e3e3e"
                            onValueChange={toggleDryCleaning}
                            value={dryCleaningPickup}
                        />
                    } />
                </Section>
                <View style={{ height: 50, width: "100%", marginTop: 40, alignItems: 'center', marginBottom: 50 }}>
                    <View style={{ width: "70%", maxWidth: 250, minWidth: 200 }} >
                        <Button
                            onPress={createPickup}
                            title='Create Pickup'
                            buttonStyle={{
                                backgroundColor: BUTTON_COLOR_TWO
                            }}
                        />
                    </View>
                </View>
            </ScrollView>
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}