import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import {
    ActivityIndicator,
    View,
    ScrollView,
    Dimensions,
    TextInput,
    Text
} from 'react-native';
import { Cell, Section, TableView } from 'react-native-tableview-simple';
import { Button } from 'react-native-elements';
import styles from './styles';
import { CELL_BACKGROUND_COLOR, CELL_TITLE_TEXT_COLOR, RIGHT_DETAIL_TEXT_COLOR, DISABLED_TEXT_INPUT_COLOR, HEADER_TEXT_COLOR, PLACEHOLDER_TEXT_INPUT_COLOR, TEXT_INPUT_COLOR, BUTTON_COLOR_TWO, CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, DRY_CLEAN_PRICES } from '../../../constants';
import { HeaderBackButton } from '@react-navigation/stack';
import RNPickerSelect, { defaultStyles } from 'react-native-picker-select';
import SearchableDropdown from 'react-native-searchable-dropdown';
import AlertModal from '../AlertModal'


export default function ProcessPayments({ navigation }) {

    useLayoutEffect(() => {
        navigation.setOptions({
            headerLeft: () => (
                <HeaderBackButton
                    onPress={() => navigation.goBack()}
                    disabled={pageLoading}
                    tintColor={HEADER_TEXT_COLOR}
                />
            )
        })
    })

    const onSubmitNumberOfPounds = () => {
        // processPayment()
    }

    const refs = {
        "pounds": useRef(),
    }

    const [flatRate, setFlatRate] = useState(false)
    const [washAndFoldPickup, setWashAndFoldPickup] = useState(false)
    const [dryCleaningPickup, setDryCleaningPickup] = useState(false)
    const [pricePerPound, setPricePerPound] = useState(0)
    const [minimumCharge, setMinimumCharge] = useState(0)
    const [washAndFoldSubtotal, setWashAndFoldSubtotal] = useState(0)
    const [dryCleaningSubtotal, setDryCleaningSubtotal] = useState(0)
    const [total, setTotal] = useState(0)

    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState([])
    const [searchText, setSearchText] = useState('')

    const [dryCleaningQuantitiesObject, setDryCleaningQuantitiesObject] = useState({})

    const [numberOfPounds, setNumberOfPounds] = useState(0)
    const [allPickupDropdownItems, setAllPickupDropdownItems] = useState()
    const [pickupDropdownItems, setPickupDropdownItems] = useState([])
    const [pickups, setPickups] = useState([])
    const [selectedPickupDropdownItem, setSelectedPickupDropdownItem] = useState(null)
    const [selectedPickup, setSelectedPickup] = useState(null)
    const [pageLoading, setPageLoading] = useState(true)
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }

    useEffect(() => {
        
        const userSubscriber = firebase.firestore().collection('userProfiles').onSnapshot(querySnapshot => {
            setPageLoading(true)
            const userDocs = querySnapshot.docs
            const usersLocal = []
            for (var i = 0; i < userDocs.length; i++) {
                const userInfo = userDocs[i].data()
                const userId = userInfo.uid
                const userName = userInfo.firstName + ' ' + userInfo.lastName
                usersLocal.push({
                    id: userId,
                    name: userName
                })
            }
            setUsers(usersLocal)
            setPageLoading(false)
        })

        const pickupSubscriber = firebase.firestore().collection('pickups').onSnapshot(querySnapshot => {
            setPageLoading(true)
            const pickupDocs = querySnapshot.docs
            pickupDocs.sort((a, b) => {
                return new Date(a.data().pickupDate) - new Date(b.data().pickupDate)
            })
            const pickupsLocal = []
            const pickupDropdownItemsLocal = []
            for (var i = 0; i < pickupDocs.length; i++) {
                if (!pickupDocs[i].data().paymentSuccessful) {
                    const label = pickupDocs[i].data().userFirstName + ' ' + pickupDocs[i].data().userLastName + ': ' + pickupDocs[i].data().pickupDay
                    pickupsLocal.push(pickupDocs[i])
                    pickupDropdownItemsLocal.push({
                        label: label,
                        value: pickupDocs[i].id,
                        key: i,
                        userId: pickupDocs[i].data().userId
                    })
                }
            }
            setPickups(pickupsLocal)
            setPickupDropdownItems(pickupDropdownItemsLocal)
            setAllPickupDropdownItems(pickupDropdownItemsLocal)
            setPageLoading(false)
        })

        return function cleanup() {
            userSubscriber()
            pickupSubscriber()
        }
    }, []);

    const selectPickup = (value) => {
        setSelectedPickupDropdownItem(value)
        var foundPickup = false
        pickups.forEach((pickup) => {
            if (pickup.id === value) {
                const pickupData = pickup.data()
                setSelectedPickup(pickup)
                setPricePerPound(pickupData.pricePerPound)
                setMinimumCharge(pickupData.minimumCharge)
                setFlatRate(pickupData.flatRate)
                setWashAndFoldPickup(pickupData.washAndFoldPickup)
                setDryCleaningPickup(pickupData.dryCleanPickup)
                setDryCleaningSubtotal(0)
                if (pickupData.flatRate) {
                    setWashAndFoldSubtotal(pickupData.washAndFoldSubtotal)
                    setTotal(pickupData.washAndFoldSubtotal)
                }
                else {
                    setWashAndFoldSubtotal(0)
                    setTotal(pickupData.minimumCharge)
                }
                foundPickup = true
            }
        })
        if (!foundPickup) {
            setSelectedPickup(null)
            setPricePerPound(0)
            setMinimumCharge(0)
            setFlatRate(false)
            setWashAndFoldSubtotal(0)
            setDryCleaningSubtotal(0)
            setTotal(0)
        }
    }

    const changeNumberOfPounds = (value) => {
        var localWashAndFoldSubtotal = value * pricePerPound
        var localTotal = localWashAndFoldSubtotal + dryCleaningSubtotal
        if (localTotal < minimumCharge) {
            localTotal = minimumCharge
        }
        setNumberOfPounds(value)
        setWashAndFoldSubtotal(localWashAndFoldSubtotal)
        setTotal(localTotal)
    }

    const processPayment = async () => {
        setPageLoading(true)
        try {
            const processPickupPayment = firebase.functions().httpsCallable('processPickupPayment')
            const response = await processPickupPayment({
                numberOfPounds: numberOfPounds,
                dryCleaningQuantitiesObject: dryCleaningQuantitiesObject,
                pickupId: selectedPickup.id,
            })
            if (response.data.success) {
                navigation.navigate('Payment Confirmation')
            }
            else {
                console.log('error process payment:', response.data.err)
                setPageLoading(false)
                openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, closeAlertModal)
            }
        }
        catch (err) {
            console.log('error processing payment', err)
            setPageLoading(false)
            openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, closeAlertModal)
            return
        }
    }

    const filterPickups = (userId, clear=false) => {
        if (clear) {
            setPickupDropdownItems(allPickupDropdownItems)
            return
        }
        const filteredPickupDropdownItems = allPickupDropdownItems.filter(item => item.userId == userId )
        setPickupDropdownItems(filteredPickupDropdownItems)
    }

    const washAndFoldFlatRateForm = () => {
        return (
            <View key={"wash and fold flat rate form"}>
                <Text style={styles.heading}>Wash and Fold</Text>
                <Text style={styles.subheading}>Subtotal</Text>
                <Section headerTextColor={HEADER_TEXT_COLOR} header="" footer="">
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} rightDetailColor={RIGHT_DETAIL_TEXT_COLOR} cellStyle="RightDetail" title="Wash and Fold Subtotal" detail={`$${washAndFoldSubtotal / 100}`} />
                </Section>
            </View>
        )
    }

    const washAndFoldPerPoundForm = () => {
        return (
            <View key={"wash and fold per pound form"}>
                <Text style={styles.heading}>Wash and Fold</Text>
                <Section headerTextColor={HEADER_TEXT_COLOR} header="" footer="">
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} rightDetailColor={RIGHT_DETAIL_TEXT_COLOR} cellStyle="RightDetail" title="Price Per Pound" detail={`$${pricePerPound / 100}`} />
                </Section>
                <Section header="# of Pounds" headerTextColor={HEADER_TEXT_COLOR} footer="">
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="# of Pounds"
                            ref={refs["pounds"]}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => changeNumberOfPounds(value)}
                            value={numberOfPounds}
                            onSubmitEditing={onSubmitNumberOfPounds}
                            returnKeyType="next"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                            keyboardType='numeric'
                        />
                    } />
                </Section>
                <Text style={styles.subheading}>Subtotal</Text>
                <Section headerTextColor={HEADER_TEXT_COLOR} header="" footer="">
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} rightDetailColor={RIGHT_DETAIL_TEXT_COLOR} cellStyle="RightDetail" title="Wash and Fold Subtotal" detail={`$${washAndFoldSubtotal / 100}`} />
                </Section>
            </View>
        )
    }

    const updateDryCleaningItemCount = (itemName, quantity) => {
        const dryCleaningQuantitiesObjectLocal = dryCleaningQuantitiesObject
        dryCleaningQuantitiesObjectLocal[itemName] = quantity
        var dryCleaningSubtotalLocal = 0
        for (const item in dryCleaningQuantitiesObjectLocal) {
            const pricePerItem = DRY_CLEAN_PRICES[item]
            const quantity = dryCleaningQuantitiesObjectLocal[item]
            const itemsCost = pricePerItem * quantity
            dryCleaningSubtotalLocal += itemsCost
        }
        var localTotal = dryCleaningSubtotalLocal + washAndFoldSubtotal
        if (localTotal < minimumCharge) {
            localTotal = minimumCharge
        }
        setDryCleaningQuantitiesObject(dryCleaningQuantitiesObjectLocal)
        setDryCleaningSubtotal(dryCleaningSubtotalLocal)
        setTotal(localTotal)
    }

    const dryCleaningForm = () => {
        const dryCleaningItems = []
        for (const item in DRY_CLEAN_PRICES) {
            dryCleaningItems.push(
                <Section key={item} header={`# of ${item} ($${DRY_CLEAN_PRICES[item] / 100} each)`} headerTextColor={HEADER_TEXT_COLOR} footer="">
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder={`# of ${item}`}
                            ref={refs[item]}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => updateDryCleaningItemCount(item, value)}
                            value={dryCleaningQuantitiesObject[item] || ''}
                            onSubmitEditing={() => {}}
                            returnKeyType="next"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                            keyboardType='numeric'
                        />
                    } />
                </Section>
            )
        }
        return (
            <View key={"dry cleaning form"}>
                <Text style={styles.heading}>Dry Cleaning</Text>
                {dryCleaningItems}
                <Text style={styles.subheading}>Subtotal</Text>
                <Section headerTextColor={HEADER_TEXT_COLOR} header="" footer="">
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} rightDetailColor={RIGHT_DETAIL_TEXT_COLOR} cellStyle="RightDetail" title="Dry Cleaning Subtotal" detail={`$${dryCleaningSubtotal / 100}`} />
                </Section>
            </View>
        )
    }

    const pickupInformation = () => {
        if (selectedPickup) {
            const items = []
            if (washAndFoldPickup) {
                if (flatRate) {
                    items.push(
                        <View key={'wash and fold flat rate'}>
                            {washAndFoldFlatRateForm()}
                        </View>
                    )
                }
                else {
                    items.push(
                        <View key={'wash and fold per pound'}>
                            {washAndFoldPerPoundForm()}
                        </View>,
                    )
                }
            }
            if (dryCleaningPickup) {
                items.push(
                    <View key={'dry cleaning pickup'}>
                        {dryCleaningForm()}
                    </View>
                )
            }
            items.push(
                <View key={"Process Payment"}>
                    <Text style={styles.heading}>Process Payment</Text>
                    <Section headerTextColor={HEADER_TEXT_COLOR} header="" footer="">
                        <Cell backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} rightDetailColor={RIGHT_DETAIL_TEXT_COLOR} cellStyle="RightDetail" title="Minimum Charge" detail={`$${minimumCharge / 100}`} />
                    </Section>
                    <Section headerTextColor={HEADER_TEXT_COLOR} header="" footer="">
                        <Cell backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} rightDetailColor={RIGHT_DETAIL_TEXT_COLOR} cellStyle="RightDetail" title="Pickup Total" detail={`$${total / 100}`} />
                    </Section>
                    <View style={{ height: 50, width: "100%", marginTop: 40, alignItems: 'center', marginBottom: 50 }}>
                        <View style={{ width: "70%", maxWidth: 250, minWidth: 200 }} >
                            <Button
                                onPress={processPayment}
                                title='Process Payment'
                                buttonStyle={{
                                    backgroundColor: BUTTON_COLOR_TWO
                                }}
                            />
                        </View>
                    </View>
                </View>
            )
            return items
        }
            
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView>
                <Section header="Select a User (optional)">
                    <SearchableDropdown
                        onItemSelect={(user) => {
                            setSelectedUser(user)
                            setSearchText(user.name)
                            filterPickups(user.id)
                            console.log('selectedUser:', user)
                        }}
                        containerStyle={{ padding: 5 }}
                        onRemoveItem={(item, index) => {
                            setSelectedUser(null)
                        }}
                        itemStyle={{
                            padding: 10,
                            marginTop: 2,
                            backgroundColor: '#ddd',
                            borderColor: '#bbb',
                            borderWidth: 1,
                            borderRadius: 5,
                        }}
                        itemTextStyle={{ color: '#222' }}
                        itemsContainerStyle={{ maxHeight: 140 }}
                        items={users}
                        defaultIndex={2}
                        resetValue={false}
                        textInputProps={
                            {
                                placeholder: "User Name",
                                underlineColorAndroid: "transparent",
                                style: {
                                    padding: 12,
                                    borderWidth: 1,
                                    borderColor: '#ccc',
                                    borderRadius: 5,
                                },
                                value: searchText,
                                onTextChange: (text) => {
                                    setSearchText(text)
                                    setSelectedUser(null)
                                    filterPickups(null, true)
                                }
                            }
                        }
                        listProps={
                            {
                                nestedScrollEnabled: true,
                            }
                        }
                    />
                </Section>
                <Section header="Select a Pickup">
                    <RNPickerSelect
                        placeholder={{
                            label: 'Select a pickup...',
                            value: 'null',
                        }}
                        items={pickupDropdownItems}
                        onValueChange={value => {
                            selectPickup(value)
                        }}
                        value={selectedPickupDropdownItem}
                    />
                </Section>
                {pickupInformation()}
            </ScrollView>
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}