import { View, ScaledSize, Dimensions, ActivityIndicator, Image, useWindowDimensions, Text } from 'react-native'
import { Appbar } from 'react-native-paper';
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import HoverableOpacity from './HoverableOpacity.js'
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { MaterialIcons } from '@expo/vector-icons';

export default function TopHeading({ navigation, currentPage }) {

    const logout = async () => {
        await firebase.auth().signOut();
    }

    const [showDrawerMenu, setShowDrawerMenu] = useState(false)

    const showDrawer = () => {
        setShowDrawerMenu(!showDrawerMenu)
    }

    const openPage = (pageName) => {
        setShowDrawerMenu(false)
        navigation.navigate(pageName)
    }

    const pageList = ['Home', 'Our Services', 'My Card']
    const logoutIcon = true
    const logoutIconPercentageDouble = 10

    const dimensions = useWindowDimensions()
    if (dimensions.width > 750) {
        const hoverMenu = []
        var widthPercentage = "20%"
        var logoutIconPercentage = logoutIconPercentageDouble.toString() + "%"
        if (logoutIcon) {
            var widthPercentageDouble = (100 - logoutIconPercentageDouble) / (pageList.length)
            var widthPercentage = widthPercentageDouble.toString() + "%"
        }
        else {
            var widthPercentageDouble = 100 / (pageList.length)
            var widthPercentage = widthPercentageDouble.toString() + "%"
        }
        for (var i = 0; i < pageList.length; i++) {
            const pageName = pageList[i]
            hoverMenu.push(
                <HoverableOpacity
                    style={{
                        width: widthPercentage,
                        height: 50,
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        backgroundColor: currentPage === pageName ? '#040405' : '#202124',
                    }}
                    onPress={() => openPage(pageName)}
                    onHover={{ backgroundColor: '#040405' }}
                    key={i}
                >
                    <Text style={{ fontSize: 20, fontWeight: '500', textAlign: 'center', color: 'white' }} >
                        {pageName}
                    </Text>
                </HoverableOpacity>
            )
        }
        if (logoutIcon) {
            hoverMenu.push(
                <HoverableOpacity
                    style={{
                        width: logoutIconPercentage,
                        height: 50,
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        backgroundColor: '#202124',
                    }}
                    onPress={logout}
                    onHover={{ backgroundColor: '#040405' }}
                    key={500}
                >
                    <View>
                        <MaterialIcons name="logout" color={'white'} size={30} />
                    </View>
                </HoverableOpacity>
            )
        }
        return (
            <View style={{ flexDirection: 'row', alignItems: 'center', backgroundColor: "white", position: "absolute", width: "100%" }}>
                {hoverMenu}
            </View>
        )
    }
    else {
        const mainPage = pageList[0]
        const mainPageHeader = (
            <HoverableOpacity
                style={{
                    height: 50,
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#202124',
                }}
                onPress={() => openPage(mainPage)}
                onHover={{ backgroundColor: '#040405' }}
            >
                <Text style={{ fontSize: 20, fontWeight: '500', textAlign: 'center', marginRight: 20, marginLeft: 20, color: 'white' }} >
                    {mainPage}
                </Text>
            </HoverableOpacity>
        )
        const hoverMenu = []
        for (var i = 1; i < pageList.length; i++) {
            const pageName = pageList[i]
            hoverMenu.push(
                <HoverableOpacity
                    style={{
                        height: 50,
                        width: "100%",
                        alignItems: 'left',
                        justifyContent: 'center',
                        textAlign: 'left',
                        backgroundColor: '#202124',
                    }}
                    onPress={() => openPage(pageName)}
                    onHover={{ backgroundColor: '#040405' }}
                    key={i}
                >
                    <Text style={{ fontSize: 20, fontWeight: '500', textAlign: 'left', marginRight: 20, marginLeft: 20, color: 'white' }} >
                        {pageName}
                    </Text>
                </HoverableOpacity>
            )
        }
        return (
            <View style={{ position: "absolute", width: "100%", height: "100%" }}>
                <View style={{ flexDirection: 'row', alignItems: 'center', width: "100%", backgroundColor: "transparent" }}>
                    {mainPageHeader}
                    <View style={{ height: 50, flex: 1, backgroundColor: '#202124' }} />
                    <HoverableOpacity
                        style={{
                            height: 50,
                            alignItems: 'left',
                            justifyContent: 'left',
                            textAlign: 'left',
                            backgroundColor: '#202124',
                        }}
                        onPress={showDrawer}
                        onHover={{ backgroundColor: '#040405' }}
                    >
                        <Appbar.Action
                            color="white"
                            icon="menu"
                            onPress={showDrawer}
                        />
                    </HoverableOpacity>
                </View>
                {
                    showDrawerMenu ? (
                        <>
                            <View style={{ flexDirection: 'column', flex: 1, alignItems: 'center', width: "100%", backgroundColor: "transparent", position: 'relative' }}>
                                <View style={{ backgroundColor: '#202124', width: "100%" }}>
                                    {hoverMenu}
                                </View>
                                <View style={{ color: "rgba(100,100,100,0.2", height: dimensions.height, width: dimensions.width }} >
                                    <HoverableOpacity
                                        style={{
                                            height: dimensions.height,
                                            width: dimensions.width,
                                            flex: 1
                                        }}
                                        onPress={showDrawer}
                                    >
                                    </HoverableOpacity>
                                </View>
                            </View>

                        </>

                    ) : (
                            <>
                            </>
                        )
                }
            </View>
        )
    }
}