import React, { useEffect, useLayoutEffect } from 'react';
import {
    View,
    Text,
    ScrollView,
    Dimensions,
} from 'react-native';
import { Button } from 'react-native-elements';
import styles from './styles';
import { Cell, Section } from 'react-native-tableview-simple';
import { BUTTON_COLOR_TWO, CELL_BACKGROUND_COLOR, CELL_TITLE_TEXT_COLOR, HEADER_TEXT_COLOR, RIGHT_DETAIL_TEXT_COLOR } from '../../../constants';


export default function Confirmation({ route, navigation }) {

    const { message, orderSummaryFields } = route.params

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: null,
            headerLeft: null
        })
    })

    const goBackToHome = () => {
        navigation.navigate("Home")
    }

    useEffect(() => {
    }, []);

    const summaryTable = () => {
        var index = 0
        var valueArray = []
        for (const property in orderSummaryFields) {
            valueArray.push(
                <Cell key={index} backgroundColor={CELL_BACKGROUND_COLOR} titleTextColor={CELL_TITLE_TEXT_COLOR} rightDetailColor={RIGHT_DETAIL_TEXT_COLOR} cellStyle="RightDetail" title={property} detail={orderSummaryFields[property]} />
            )
            index++
        }
        return valueArray
    }

    const returnToResidentialPage = () => {
        navigation.navigate('Residential')
    }

    return (
        <View style={styles.parent}>
            <ScrollView>
                <Text style={styles.heading}>{message}</Text>
                <Section headerTextColor={HEADER_TEXT_COLOR} header="ORDER SUMMARY" footer="">
                    {summaryTable()}
                </Section>
                <View style={{ height: 50, width: "100%", marginTop: 40, alignItems: 'center', marginBottom: 50 }}>
                    <View style={{ width: "70%", maxWidth: 250, minWidth: 200 }} >
                    <Button
                            onPress={returnToResidentialPage}
                            title='Back to Residential Page'
                            buttonStyle={{
                                backgroundColor: BUTTON_COLOR_TWO
                            }}
                        />
                    </View>
                </View>
            </ScrollView>
        </View>
    );
}