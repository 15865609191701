import React, { Component, useState, useEffect, useContext, useRef, useCallback, useLayoutEffect } from 'react';
import {
    View,
    SafeAreaView,
    Text,
    Alert,
    ScrollView,
    Image,
    Dimensions,
    TextInput,
    ActivityIndicator,
    useWindowDimensions,
    Switch
} from 'react-native';
import { Button, Input, Card } from 'react-native-elements';
import styles from './styles';
import { Cell, Section, TableView } from 'react-native-tableview-simple';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { useFocusEffect } from '@react-navigation/native';
import { ACTIVITY_INDICATOR_COLOR_ONE, BACKGROUND_COLOR, BUTTON_COLOR_ONE, BUTTON_COLOR_TWO, CELL_BACKGROUND_COLOR, CELL_TITLE_TEXT_COLOR, HEADER_TEXT_COLOR, PLACEHOLDER_TEXT_INPUT_COLOR, SEPARATOR_TINT_COLOR_TWO, TEXT_COLOR_FOUR, TEXT_INPUT_COLOR } from '../../../constants';
import AlertModal from '../AlertModal'
import RNPickerSelect, { defaultStyles } from 'react-native-picker-select';

export default function AddUser({ navigation }) {

    const createUserProfile = async (addBooking) => {
        // add user profile
        if (!firstName || firstName === '') {
            openAlertModal('Error', 'Please enter first name.', 'Ok', closeAlertModal)
            return
        }
        if (!lastName || lastName === '') {
            openAlertModal('Error', 'Please enter last name.', 'Ok', closeAlertModal)
            return
        }
        if (!email || email === '') {
            openAlertModal('Error', 'Please enter email.', 'Ok', closeAlertModal)
            return
        }
        if (!phoneNumber || phoneNumber === '') {
            openAlertModal('Error', 'Please enter phone number.', 'Ok', closeAlertModal)
            return
        }
        if (!detergent || detergent === '') {
            openAlertModal('Error', 'Please enter detergent.', 'Ok', closeAlertModal)
            return
        }
        if (!dryerSheet || dryerSheet === '') {
            openAlertModal('Error', 'Please enter dryer sheet.', 'Ok', closeAlertModal)
            return
        }
        if (!fabricSoftener || fabricSoftener === '') {
            openAlertModal('Error', 'Please enter fabric softener.', 'Ok', closeAlertModal)
            return
        }
        if (!preferredContactMethod || preferredContactMethod === '') {
            openAlertModal('Error', 'Please enter preferred contact method.', 'Ok', closeAlertModal)
            return
        }
        if (!textReminders || textReminders === '') {
            openAlertModal('Error', 'Please enter text reminders.', 'Ok', closeAlertModal)
            return
        }
        await firebase.firestore().collection('hotelUserProfiles').add({
            firstName,
            lastName,
            email,
            phoneNumber,
            detergent,
            dryerSheet,
            fabricSoftener,
            preferredContactMethod,
            textReminders,
            additionalInstructions
        })
        if (addBooking) {
            navigation.navigate('Add Hotel Booking')
        }
        else {
            navigation.navigate('Hotel Users')
        }
    }

    const fetchLaundryInfo = async () => {
        setPageLoading(true)
        const laundryInfoDoc = await firebase.firestore().collection('pageInfo').doc('laundry').get()
        const laundryInfo = laundryInfoDoc.data()
        const detergentListLocal = []
        const dryerSheetListLocal = []
        const fabricSoftenerListLocal = []
        for (var i = 0; i < laundryInfo.detergent.length; i++) {
            const detergentItem = laundryInfo.detergent[i]
            detergentListLocal.push({
                label: detergentItem,
                value: detergentItem,
                key: i
            })
        }
        for (var i = 0; i < laundryInfo.dryerSheet.length; i++) {
            const dryerSheetItem = laundryInfo.dryerSheet[i]
            dryerSheetListLocal.push({
                label: dryerSheetItem,
                value: dryerSheetItem,
                key: i
            })
        }
        for (var i = 0; i < laundryInfo.fabricSoftener.length; i++) {
            const fabricSoftenerItem = laundryInfo.fabricSoftener[i]
            fabricSoftenerListLocal.push({
                label: fabricSoftenerItem,
                value: fabricSoftenerItem,
                key: i
            })
        }
        setDetergentList(detergentListLocal)
        setDryerSheetList(dryerSheetListLocal)
        setFabricSoftenerList(fabricSoftenerListLocal)
        setPageLoading(false)
    }

    useEffect(() => {
        fetchLaundryInfo()
    }, [])
    
    const refs = {
        'firstName': useRef(),
        'lastName': useRef(),
        'email': useRef(),
        'phoneNumber': useRef(),
        'detergent': useRef(),
        'dryerSheet': useRef(),
        'fabricSoftener': useRef(),
        'preferredContactMethod': useRef(),
        'textReminders': useRef(),
        'additionalInstructions': useRef(),
    };

    const [pageLoading, setPageLoading] = useState(true)
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }

    const toggleTextRemindersSwitch = () => {
        setTextReminders(previousState => !previousState);
    }

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [detergent, setDetergent] = useState('')
    const [dryerSheet, setDryerSheet] = useState('')
    const [fabricSoftener, setFabricSoftener] = useState('')
    const [preferredContactMethod, setPreferredContactMethod] = useState('')
    const [textReminders, setTextReminders] = useState(true)
    const [additionalInstructions, setAdditionalInstructions] = useState('')

    const [detergentList, setDetergentList] = useState([])
    const [dryerSheetList, setDryerSheetList] = useState([])
    const [fabricSoftenerList, setFabricSoftenerList] = useState([])

    const [preferredContactMethodList, setPreferredContactMethodList] = useState([
        {
            label: 'Call',
            value: 'Call',
            key: 0
        },
        {
            label: 'Text',
            value: 'Text',
            key: 1
        },
        {
            label: 'Email',
            value: 'Email',
            key: 2
        },
    ])

    const onSubmitFirstName = () => {
        refs['lastName'].current.focus()
    }

    const onSubmitLastName = () => {
        refs['email'].current.focus()
    }

    const onSubmitEmail = () => {
        refs['phoneNumber'].current.focus()
    }

    const onSubmitPhoneNumber = () => {
        refs['detergent'].current.focus()
    }

    const onSubmitDetergent = () => {
        refs['dryerSheet'].current.focus()
    }

    const onSubmitDryerSheet = () => {
        refs['fabricSoftener'].current.focus()
    }

    const onSubmitFabricSoftener = () => {
        refs['preferredContactMethod'].current.focus()
    }

    const onSubmitPreferredContactMethod = () => {
        refs['textReminders'].current.focus()
    }

    const onSubmitTextReminders = () => {
        refs['additionalInstructions'].current.focus()
    }

    const onSubmitAdditionalInstructions = () => {
        createUserProfile()
    }

    if (pageLoading) {
        return (
            <View>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView>
                <Section>
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="First Name"
                            ref={refs["firstName"]}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => setFirstName(value)}
                            value={firstName}
                            onSubmitEditing={onSubmitFirstName}
                            returnKeyType="next"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                        />
                    } />
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="Last Name"
                            ref={refs["lastName"]}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => setLastName(value)}
                            value={lastName}
                            onSubmitEditing={onSubmitLastName}
                            returnKeyType="next"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                        />
                    } />
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="Email"
                            ref={refs["email"]}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => setEmail(value)}
                            value={email}
                            onSubmitEditing={onSubmitEmail}
                            returnKeyType="next"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                        />
                    } />
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="Phone Number"
                            ref={refs["phoneNumber"]}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => setPhoneNumber(value)}
                            value={phoneNumber}
                            onSubmitEditing={onSubmitPhoneNumber}
                            returnKeyType="next"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                        />
                    } />
                </Section>
                <Section header="Detergent">
                    <RNPickerSelect
                        placeholder={{
                            label: 'Select detergent...',
                            value: 'null',
                        }}
                        items={detergentList}
                        onValueChange={value => {
                            setDetergent(value)
                        }}
                        value={detergent}
                    />
                </Section>
                <Section header="Dryer Sheet">
                    <RNPickerSelect
                        placeholder={{
                            label: 'Select dryer sheet...',
                            value: 'null',
                        }}
                        items={dryerSheetList}
                        onValueChange={value => {
                            setDryerSheet(value)
                        }}
                        value={dryerSheet}
                    />
                </Section>
                <Section header="Fabric Softener">
                    <RNPickerSelect
                        placeholder={{
                            label: 'Select fabric softener...',
                            value: 'null',
                        }}
                        items={fabricSoftenerList}
                        onValueChange={value => {
                            setFabricSoftener(value)
                        }}
                        value={fabricSoftener}
                    />
                </Section>
                <Section header="Preferred Contact Method">
                    <RNPickerSelect
                        placeholder={{
                            label: 'Select preferred contact method...',
                            value: 'null',
                        }}
                        items={preferredContactMethodList}
                        onValueChange={value => {
                            setPreferredContactMethod(value)
                        }}
                        value={preferredContactMethod}
                    />
                </Section>
                <Section header="Text Reminders">
                    <View style={{ marginLeft: 15, marginTop: 10, marginBottom: 10 }}>
                        <Switch
                            trackColor={{ false: "#767577", true: "#81b0ff" }}
                            thumbColor={textReminders ? "#f5dd4b" : "#f4f3f4"}
                            ios_backgroundColor="#3e3e3e"
                            onValueChange={toggleTextRemindersSwitch}
                            value={textReminders}
                        />
                    </View>
                </Section>
                <Section>
                    <Cell backgroundColor={CELL_BACKGROUND_COLOR} cellContentView={
                        <TextInput
                            style={{ fontSize: 16, flex: 1, color: TEXT_INPUT_COLOR }}
                            placeholder="Additional Instructions"
                            ref={refs["additionalInstructions"]}
                            autoCorrect={false}
                            enablesReturnKeyAutomatically={true}
                            onChangeText={(value) => setAdditionalInstructions(value)}
                            value={additionalInstructions}
                            onSubmitEditing={onSubmitAdditionalInstructions}
                            returnKeyType="next"
                            placeholderTextColor={PLACEHOLDER_TEXT_INPUT_COLOR}
                        />
                    } />
                </Section>
                <View style={{ height: 50, width: "100%", marginTop: 40, alignItems: 'center' }}>
                    <View style={{ width: "70%", maxWidth: 250, minWidth: 200 }} >
                        <Button
                            onPress={() => createUserProfile(false)}
                            title='Create User Profile'
                            buttonStyle={{
                                backgroundColor: BUTTON_COLOR_TWO
                            }}
                        />
                    </View>
                </View>
                <View style={{ height: 50, width: "100%", marginTop: 40, alignItems: 'center', marginBottom: 50 }}>
                    <View style={{ width: "70%", maxWidth: 250, minWidth: 200 }} >
                        <Button
                            onPress={() => createUserProfile(true)}
                            title='Create User Profile & Add Booking'
                            buttonStyle={{
                                backgroundColor: BUTTON_COLOR_TWO
                            }}
                        />
                    </View>
                </View>
            </ScrollView>
            <AlertModal
                modalVisible={alertModalVisibile}
                setModalVisible={setAlertModalVisible}
                alertTitleText={alertInfo.alertTitleText}
                alertSubtitleText={alertInfo.alertSubtitleText}
                alertPrimaryButtonText={alertInfo.alertPrimaryButtonText}
                alertPrimaryAction={alertInfo.alertPrimaryAction}
                alertSecondaryButtonText={alertInfo.alertSecondaryButtonText}
                alertSecondaryAction={alertInfo.alertSecondaryAction}
            />
        </View>
    );
}