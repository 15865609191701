import React, { useEffect, useState, useRef } from 'react';
import {
    View,
    Dimensions,
    ActivityIndicator,
    Text,
    Alert,
    useWindowDimensions,
    TouchableOpacity,
    TouchableHighlight
} from 'react-native';
import { Button } from 'react-native-elements';
import Modal from 'react-native-modal'
import { BACKGROUND_COLOR, CELL_BACKGROUND_COLOR, TEXT_INPUT_COLOR, TEXT_COLOR_THREE, HEADER_TEXT_COLOR, ACCENT_COLOR_THREE, SEPARATOR_TINT_COLOR_TWO, UNDERLAY_COLOR_ONE } from '../../../constants';

export default function AlertModal({
    modalVisible,
    setModalVisible,
    alertTitleText,
    alertSubtitleText,
    alertPrimaryButtonText,
    alertPrimaryAction,
    alertSecondaryButtonText,
    alertSecondaryAction,
}) {

    const dimensions = useWindowDimensions()
    const [] = useState(false)
    const [modalLoading] = useState(false)

    useEffect(() => {

    }, [modalVisible, alertPrimaryButtonText, alertPrimaryAction, alertSecondaryButtonText, alertSecondaryAction])

    var widthPercent = "100%"
    var marginPercent = "0%"
    if (dimensions.width >= 1100) {
        widthPercent = 550;
        marginPercent = (dimensions.width - 550) / 2;
    }
    else if ((dimensions.width >= 500) && (dimensions.width < 1100)) {
        widthPercent = "50%";
        marginPercent = "25%";
    }
    else {
        widthPercent = "80%";
        marginPercent = "10%"
    }

    const closeAlertModal = () => {
        setModalVisible(false)
    }

    const alertButtons = () => {
        if (!alertSecondaryButtonText || alertSecondaryButtonText === '') {
            // only one button
            return (
                <View style={{ width: "100%" }}>
                    <TouchableHighlight style={{ alignItems: 'center' }} underlayColor={UNDERLAY_COLOR_ONE} onPress={alertPrimaryAction}>
                        <View style={{ alignItems: 'center', width: "100%" }}>
                            <Text style={{ marginTop: 15, marginBottom: 15, fontSize: 15, color: TEXT_COLOR_THREE, fontWeight: 'bold' }}>{alertPrimaryButtonText}</Text>
                        </View>
                    </TouchableHighlight>
                </View>
            )
        }
        else {
            // two buttons
            return (
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <View style={{ width: "50%", borderRightWidth: 1, borderRightColor: SEPARATOR_TINT_COLOR_TWO }}>
                        <TouchableHighlight style={{ alignItems: 'center' }} underlayColor={UNDERLAY_COLOR_ONE} onPress={alertSecondaryAction}>
                            <View style={{ alignItems: 'center', width: "100%" }}>
                                <Text style={{ marginTop: 15, marginBottom: 15, fontSize: 15, color: TEXT_COLOR_THREE, fontWeight: 'normal' }}>{alertSecondaryButtonText}</Text>
                            </View>
                        </TouchableHighlight>
                    </View>
                    <View style={{ width: "50%", borderLeftWidth: 1, borderLeftColor: SEPARATOR_TINT_COLOR_TWO }}>
                        <TouchableHighlight style={{ alignItems: 'center' }} underlayColor={UNDERLAY_COLOR_ONE} onPress={alertPrimaryAction}>
                            <View style={{ alignItems: 'center', width: "100%" }}>
                                <Text style={{ marginTop: 15, marginBottom: 15, fontSize: 15, color: TEXT_COLOR_THREE, fontWeight: 'bold' }}>{alertPrimaryButtonText}</Text>
                            </View>
                        </TouchableHighlight>
                    </View>
                </View>

            )
        }
    }

    if (modalLoading) {
        return (
            <Modal isVisible={modalVisible} propagateSwipe={true} style={{ justifyContent: 'flex-end', margin: 0 }} >
                <View style={{ width: widthPercent, minWidth: 300, backgroundColor: BACKGROUND_COLOR, borderRadius: 5 }}>
                    <ActivityIndicator />
                </View>
            </Modal >
        )
    }

    return (
        <Modal isVisible={modalVisible} keyboardShouldPersistTaps="handled" propagateSwipe={true} style={{ alignItems: 'center' }} >
            <View style={{ width: widthPercent, minWidth: 300, backgroundColor: BACKGROUND_COLOR, borderRadius: 5 }}>
                <View style={{ alignItems: 'center' }}>
                    <Text style={{ textAlign: 'center', color: HEADER_TEXT_COLOR, fontSize: 30, marginTop: 20 }}>{alertTitleText}</Text>
                </View>
                <View style={{ alignItems: 'center' }}>
                    <Text style={{ textAlign: 'center', color: HEADER_TEXT_COLOR, fontSize: 20, marginTop: 20, marginLeft: 10, marginRight: 10 }}>{alertSubtitleText}</Text>
                </View>
                <View style={{ marginTop: 20, borderTopWidth: 1, borderTopColor: SEPARATOR_TINT_COLOR_TWO }}>
                    {alertButtons()}
                </View>
            </View>
        </Modal>
    );
}