import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import {
    ActivityIndicator,
    View,
    ScrollView,
    Dimensions,
    TextInput,
    Text
} from 'react-native';
import { Cell, Section, TableView } from 'react-native-tableview-simple';
import { Button } from 'react-native-elements';
import styles from './styles';
import { CELL_BACKGROUND_COLOR, CELL_TITLE_TEXT_COLOR, RIGHT_DETAIL_TEXT_COLOR, DISABLED_TEXT_INPUT_COLOR, HEADER_TEXT_COLOR, PLACEHOLDER_TEXT_INPUT_COLOR, TEXT_INPUT_COLOR, BUTTON_COLOR_TWO, CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON } from '../../../constants';
import { HeaderBackButton } from '@react-navigation/stack';
import RNPickerSelect, { defaultStyles } from 'react-native-picker-select';


export default function Confirmation({ navigation }) {

    useLayoutEffect(() => {
        navigation.setOptions({
        })
    })

    const onSubmitNumberOfPounds = () => {
        nextPage()
    }

    const refs = {
        "pounds": useRef(),
    }
    
    const [flatRate, setFlatRate] = useState(false)
    const [pricePerPound, setPricePerPound] = useState(0)
    const [minimumCharge, setMinimumCharge] = useState(0)
    const [total, setTotal] = useState(0)

    const [numberOfPounds, setNumberOfPounds] = useState(0)
    const [pickupDropdownItems, setPickupDropdownItems] = useState([])
    const [pickups, setPickups] = useState([])
    const [selectedPickupDropdownItem, setSelectedPickupDropdownItem] = useState(null)
    const [selectedPickup, setSelectedPickup] = useState(null)
    const [pageLoading, setPageLoading] = useState(true)
    const [alertModalVisibile, setAlertModalVisible] = useState(false);
    const [alertInfo, setAlertInfo] = useState({})
    const closeAlertModal = () => {
        setAlertModalVisible(false)
    }
    const openAlertModal = (title, subtitle, primaryButtonText, primaryButtonAction, secondaryButtonText = null, secondaryButtonAction = null) => {
        setAlertInfo({
            alertTitleText: title,
            alertSubtitleText: subtitle,
            alertPrimaryButtonText: primaryButtonText,
            alertPrimaryAction: () => primaryButtonAction(),
            alertSecondaryButtonText: secondaryButtonText,
            alertSecondaryAction: () => secondaryButtonAction()
        })
        setAlertModalVisible(true)
    }

    useEffect(() => {
        const pickupSubscriber = firebase.firestore().collection('pickups').onSnapshot(querySnapshot => {
            setPageLoading(true)
            const pickupDocs = querySnapshot.docs
            pickupDocs.sort((a, b) => {
                return new Date(a.data().pickupDate) - new Date(b.data().pickupDate)
            })
            const pickupsLocal = []
            const pickupDropdownItemsLocal = []
            // only display current pickups (using yesterday to add a bit of cusion)
            let yesterdayDate = new Date(Date.now() - (1000 * 60 * 60 * 24))
            for (var i = 0; i < pickupDocs.length; i++) {
                let pickupDate = new Date(pickupDocs[i].data().pickupDate)
                if (pickupDate >= yesterdayDate && !pickupDocs[i].data().paymentSuccessful) {
                    const label = pickupDocs[i].data().userFirstName + ' ' + pickupDocs[i].data().userLastName + ': ' + pickupDocs[i].data().pickupDay
                    pickupsLocal.push(pickupDocs[i])
                    pickupDropdownItemsLocal.push({
                        label: label,
                        value: pickupDocs[i].id,
                        key: i
                    })
                }
            }
            setPickups(pickupsLocal)
            setPickupDropdownItems(pickupDropdownItemsLocal)
            setPageLoading(false)
        })

        return function cleanup() {
            pickupSubscriber()
        }
    }, []);

    const selectPickup = (value) => {
        setSelectedPickupDropdownItem(value)
        var foundPickup = false
        pickups.forEach((pickup) => {
            if (pickup.id === value) {
                setSelectedPickup(pickup)
                setPricePerPound(pickup.data().pricePerPound)
                setMinimumCharge(pickup.data().minimumCharge)
                setFlatRate(pickup.data().flatRate)
                setTotal(pickup.data().total)
                foundPickup = true
            }
        })
        if (!foundPickup) {
            setSelectedPickup(null)
            setPricePerPound(0)
            setMinimumCharge(0)
            setFlatRate(false)
            setTotal(0)
        }
    }

    const changeNumberOfPounds = (value) => {
        setNumberOfPounds(value)
        var localTotal = value * pricePerPound
        if (localTotal < minimumCharge) {
            localTotal = minimumCharge
        }
        setTotal(localTotal)
    }

    const processPayment = async () => {

        try {

        }
        catch {
            console.log('error updating preferred pickup day and time', err)
            setPageLoading(false)
            openAlertModal(CONNECTION_ERROR_HEADER, CONNECTION_ERROR_MESSAGE, CONNECTION_ERROR_BUTTON, closeAlertModal)
            return
        }
    }

    const returnToResidentialPage = () => {
        navigation.navigate('Residential')
    }

    if (pageLoading) {
        return (
            <View style={styles.parent}>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView>
                <View style={{ marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10 }}>
                    <Text style={styles.subsubheading}>Success!</Text>
                </View>
                <View style={{ height: 50, width: "100%", marginTop: 40, alignItems: 'center', marginBottom: 50 }}>
                    <View style={{ width: "70%", maxWidth: 250, minWidth: 200 }} >
                        <Button
                            onPress={returnToResidentialPage}
                            title='Back to Residential Page'
                            buttonStyle={{
                                backgroundColor: BUTTON_COLOR_TWO
                            }}
                        />
                    </View>
                </View>
            </ScrollView>
        </View>
    );
}