import React, { Component, useState, useEffect, useContext, useRef, useCallback } from 'react';
import {
    View,
    SafeAreaView,
    Text,
    Alert,
    ScrollView,
    Image,
    Dimensions,
    TextInput,
    ActivityIndicator,
    useWindowDimensions,
} from 'react-native';
import { Button, Input, Card } from 'react-native-elements';
import styles from './styles';
import { Cell, Section, TableView } from 'react-native-tableview-simple';
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { useFocusEffect } from '@react-navigation/native';
import { BUTTON_COLOR_ONE } from '../../../constants';


export default function HotelWashCycle() {

    const [pageLoading, setPageLoading] = useState(true)

    useEffect(() => {
        setPageLoading(false)
    }, [])

    if (pageLoading) {
        return (
            <View>
                <ActivityIndicator />
            </View>
        )
    }

    return (
        <View style={styles.parent}>
            <ScrollView>
                <Text style={{ marginTop: 10, fontSize: 25, fontWeight: 'bold' }}>Wash Cycle</Text>
            </ScrollView>
        </View>
    );
}